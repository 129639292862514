/*
 * @Author: Try
 * @Date: 2023-09-25 10:50:29
 * @LastEditTime: 2023-11-16 15:44:22
 * @LastEditors: Do not edit
 * @FilePath: \shulong_tenant_pc\src\api\user.ts
 * @Description: 头部注释配置模板
 */
import request from '@/utils/request';
import { encryptedData } from '@/utils/encrypt';
import { loginRSA } from '@/config';
// export async function login(data: any) {
//   if (loginRSA) {
//     data = await encryptedData(data)
//   }
//   return request({
//     url: '/login',
//     method: 'post',
//     data,
//   })
// }
export function login(data) {
    return request({
        url: '/passport/login',
        method: 'post',
        data,
    });
}
export function cptLogin(data) {
    return request({
        url: '/passport/smsLogin',
        method: 'post',
        data,
    });
}
export async function socialLogin(data) {
    if (loginRSA) {
        data = await encryptedData(data);
    }
    return request({
        url: '/socialLogin',
        method: 'post',
        data,
    });
}
export function getUserInfo() {
    return request({
        url: '/account/getUserInfo',
        method: 'post',
    });
}
export function logout() {
    return request({
        url: '/logout',
        method: 'get',
    });
}
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data,
    });
}
export function cpt(data) {
    return request({
        url: '/passport/sendPhoneCode',
        method: 'post',
        data,
    });
}
export function resetPassword(data) {
    return request({
        url: '/passport/resetPwd',
        method: 'post',
        data,
    });
}
export function tenantExchange(data) {
    return request({
        url: '/account/tenantExchange',
        method: 'post',
        data,
    });
}
/*
  组织员工
*/
// 修改我的密码
export function updateAccountPwd(data) {
    return request({
        url: '/account/updateAccountPwd',
        method: 'post',
        data,
    });
}
// 验证密码
export function verifyPwdAPI(data) {
    return request({
        url: '/account/verifyPwd',
        method: 'post',
        data,
    });
}
