import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a95dbcd4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-nav"
};
const _hoisted_2 = {
  class: "left-panel"
};
const _hoisted_3 = {
  class: "right-panel overflow-hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_fold = _resolveComponent("vab-fold");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_vab_breadcrumb = _resolveComponent("vab-breadcrumb");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_vab_error_log = _resolveComponent("vab-error-log");
  const _component_vab_lock = _resolveComponent("vab-lock");
  const _component_vab_search = _resolveComponent("vab-search");
  const _component_vab_notice = _resolveComponent("vab-notice");
  const _component_vab_full_screen = _resolveComponent("vab-full-screen");
  const _component_vab_language = _resolveComponent("vab-language");
  const _component_vab_theme = _resolveComponent("vab-theme");
  const _component_vab_refresh = _resolveComponent("vab-refresh");
  const _component_vab_avatar = _resolveComponent("vab-avatar");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 15
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      lg: 12,
      md: 12,
      sm: 12,
      xl: 12,
      xs: 4
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [$props.layout !== 'float' ? (_openBlock(), _createBlock(_component_vab_fold, {
        key: 0
      })) : _createCommentVNode("v-if", true), $props.layout === 'comprehensive' ? (_openBlock(), _createBlock(_component_el_tabs, {
        key: 1,
        modelValue: $setup.tab.data,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.tab.data = $event),
        "tab-position": "top",
        onTabClick: $setup.handleTabClick
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, (item, index) => {
          return _openBlock(), _createBlock(_component_el_tab_pane, {
            key: index + item.name,
            name: item.name
          }, {
            label: _withCtx(() => [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
              key: 0,
              icon: item.meta.icon,
              "is-custom-svg": item.meta.isCustomSvg,
              style: {
                "min-width": "16px"
              }
            }, null, 8 /* PROPS */, ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString($setup.translate(item.meta.title)), 1 /* TEXT */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])) : (_openBlock(), _createBlock(_component_vab_breadcrumb, {
        key: 2,
        class: "hidden-xs-only"
      }))])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_el_col, {
      lg: 12,
      md: 12,
      sm: 12,
      xl: 12,
      xs: 20
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_select, {
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.value = $event),
        class: "company",
        placeholder: "选择公司",
        onChange: $setup.fetchData
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.company, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            value: item.value,
            label: item.companyName
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.companyName) + " ", 1 /* TEXT */), _createCommentVNode(" <span class=\"companyCard\">超级管理员</span> "), _createVNode(_component_el_tag, {
              effect: "plain",
              type: "info",
              style: {
                "margin-left": "10px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.master === 1 ? '超级管理员' : '普通人员'), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "label"]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_vab_error_log), _createVNode(_component_vab_lock), _createVNode(_component_vab_search), _createVNode(_component_vab_notice), _createVNode(_component_vab_full_screen), _createVNode(_component_vab_language), _createVNode(_component_vab_theme), _createVNode(_component_vab_refresh), _createVNode(_component_vab_avatar)])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  })]);
}