/*
 * @Description:
 * @Version: 3.0
 * @Autor: LIN
 * @Date: 2023-09-02 17:25:42
 * @LastEditors: Seven
 * @LastEditTime: 2024-08-20 17:10:28
 */
import { createApp } from 'vue';
import App from './App.vue';
import { setupVab } from '~/library';
import { setupI18n } from '@/i18n';
import { setupStore } from '@/store';
import { setupRouter } from '@/router';
import vPreviewImage from 'v-preview-image';
import '@/assets/css/index.scss';
import directives from '@/directives/index';
import VxeUITable from 'vxe-table';
import 'vxe-table/lib/style.css';
import VxeUI from 'vxe-pc-ui';
import 'vxe-pc-ui/lib/style.css';
// import './global/v-click-outside' // 导入全局插件文件
// 其他应用程序配置...
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { baseURL, pwa } from './config';
import { isExternal } from '@/utils/validate';
const app = createApp(App);
app.use(vPreviewImage);
import nodeWrap from '@/views/setting/approve/components/nodeWalk.vue';
app.component('NodeWrap', nodeWrap); //初始化组件
// app.config.warnHandler = function (msg, vm, trace) {}
if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
    const { mockXHR } = require('@/utils/static');
    mockXHR();
}
app.use(VxeUITable).use(VxeUI);
// app.use(enterNumber)
// app.directive('enterNumber', {
//   mounted(el, { value = 100 }, vnode) {
//     el = el.nodeName == 'INPUT' ? el : el.children[0]
//     const RegStr =
//       value == 0
//         ? `^[\\+\\-]?\\d+\\d{0,0}`
//         : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
//     el.addEventListener('input', function () {
//       el.value = el.value.match(new RegExp(RegStr, 'g'))
//       el.dispatchEvent(new Event('input'))
//     })
//   },
// })
// app.directive('enter-number', {
//   mounted(el: HTMLElement, binding) {
//     let inp: HTMLInputElement
//     if (el.tagName.toLowerCase() == 'input') {
//       inp = el as HTMLInputElement
//     } else {
//       inp = el.querySelector('input') as HTMLInputElement
//     }
//     // 金额限制
//     function limitMoney(value: string) {
//       return value
//         .replace(/[^\d.]/g, '')
//         .replace(/\.{2,}/g, '.')
//         .replace(/^0+/g, '0')
//         .replace(/^0+\./g, '0.')
//         .replace(/^0+\d/g, '0')
//         .replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
//     }
//     // 整数限制
//     function limitInteger(value: string) {
//       return value
//         .replace(/[^\d]/g, '')
//         .replace(/^0+/g, '0')
//         .replace(/^0+\d/g, '0')
//     }
//     inp.addEventListener('input', () => {
//       // 金额限制
//       if (binding.modifiers.money)
//         return (inp.value = limitMoney(inp.value.toString()))
//       // 整数限制
//       if (binding.modifiers.int)
//         return (inp.value = limitInteger(inp.value.toString()))
//     })
//   },
// })
app.use(directives);
/**
 * @description 生产环境启用组件初始化，编译，渲染和补丁性能跟踪。仅在开发模式和支持 Performance.mark API的浏览器中工作。
 */
//if (process.env.NODE_ENV === 'development') app.config.performance = true
if (pwa)
    require('./registerServiceWorker');
setupVab(app);
setupI18n(app);
setupStore(app);
setupRouter(app)
    .isReady()
    .then(() => app.mount('#app'));
const debounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        let context = this;
        timer = setTimeout(function () {
            try {
                fn.apply(context, args);
                clearTimeout(timer);
            }
            catch (err) {
                // console.log(err)
            }
        }, delay);
    };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
    }
};
window.onerror = function (message, source, lineno, colno, error) {
    console.log('message', error);
};
