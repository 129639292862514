import "core-js/modules/es.array.push.js";
/*
 * @Author: Try
 * @Date: 2023-10-09 10:09:22
 * @LastEditTime: 2024-06-05 10:20:48
 * @LastEditors: Seven
 * @FilePath: \shulong_tenant_pc\src\hooks\useNotice.ts
 * @Description: 头部注释配置模板
 */
// 消息通知
// import { useNoticeStore } from '@/store/modules/notice.ts'
import { EApprovalProcessType } from '@/enums/approval.ts';
import { gp } from '@gp';
export default function useNotice() {
  const router = useRouter();
  const route = useRoute();
  // const noticeStore = useNoticeStore()
  // 工作台 - 审批流 - 消息通知
  const approvalProcessTypeMap = new Map([[EApprovalProcessType.tender, 'CostManagementDetail'],
  // 投标成本
  [EApprovalProcessType.tenderChange, 'CostChangesContent'],
  // 投标成本变更
  [EApprovalProcessType.tenderDoc, 'BidContentDetail'], [EApprovalProcessType.assure, 'AssureContentDetail'], [EApprovalProcessType.tenderRefund, 'RefundContentDetail'], [EApprovalProcessType.designChange, 'DesignChangeDetail'], [EApprovalProcessType.projectContract, 'ContractDetail'], [EApprovalProcessType.visaProject, 'VisaDesignDetail'], [EApprovalProcessType.collection, 'ProjectCollectionDetail'], [EApprovalProcessType.projectCost, 'ProjectCostDetail'],
  // 施工成本
  [EApprovalProcessType.meeting, 'MeetingDetail'], [EApprovalProcessType.constructionLog, 'ConstructionLogDetail'], [EApprovalProcessType.budget, 'BudgetDetail'],
  // 投标预算
  [EApprovalProcessType.projectPayment, 'ProjectPaymentContent'], [EApprovalProcessType.projectOutputValue, 'ProjectOutputValueContent'], [EApprovalProcessType.laborContract, 'LaborSubContent'], [EApprovalProcessType.costChange, 'CostChangesContent'], [EApprovalProcessType.majorPackage, 'ProfessionalSubContent'], [EApprovalProcessType.machineryLeaseContract, 'EquipmentManagementContent'], [EApprovalProcessType.teamSettlement, 'TeamSettlementContent'], [EApprovalProcessType.retentionMoney, 'WarrantyContent'], [EApprovalProcessType.materialRequisition, 'MaterialContent'], [EApprovalProcessType.pickLists, 'PickListApproval'],
  // 材料领料
  [EApprovalProcessType.materialReturn, 'ReturnPickContent'],
  // 材料退料
  [EApprovalProcessType.procurementContract, 'ProcurementContractContent'], [EApprovalProcessType.procurementManagement, 'QuotationContent'],
  // 报价单管理
  [EApprovalProcessType.purchaseOrder, 'PurchaseOrderOpeningContent'],
  // 订购单开立
  [EApprovalProcessType.warehouseCheck, 'InventoryCountApproval'],
  // 库存盘点
  [EApprovalProcessType.warehouseAllocate, 'MaterialTransferContent'],
  // 材料调拨
  [EApprovalProcessType.warehouseReclaim, 'ScrapRecycleContent'],
  // 废料回收
  [EApprovalProcessType.materialAcceptance, 'MaterialAcceptanceContent'],
  // 材料验收
  [EApprovalProcessType.financeLease, 'DebitNoteContent'],
  // 借支单
  [EApprovalProcessType.reimbursement, 'ReimbursementContent'],
  // 费用报销
  [EApprovalProcessType.payWarrant, 'PayWarrantContent'],
  // 借支证明单
  [EApprovalProcessType.purchaseRequisition, 'PurchaseRequisitionContent'],
  // 材料申购
  [EApprovalProcessType.settlementManagement, 'SettlementManagementtApproval'],
  // 结算管理
  [EApprovalProcessType.generalHandleStorage, 'GeneralHandleStorageapproval'],
  // 通用出入库
  [EApprovalProcessType.roster, 'RosterApproval'],
  // 员工花名册
  [EApprovalProcessType.wasteAllocate, 'WasteAllocationContent'],
  // 废料调拨
  [EApprovalProcessType.wasteDisposal, 'WasteDisposalContent'],
  // 废料处理
  [EApprovalProcessType.DocumentDorrowingAndReturning, 'DocumentDorrowingAndReturningContent'],
  // 证件借还
  [EApprovalProcessType.rewards, 'RewardsContent'],
  // 奖惩管理
  [EApprovalProcessType.regularManagement, 'RegularManagementContent'],
  // 转正管理
  [EApprovalProcessType.paymentApplication, 'PaymentApplicationApproavl'],
  // 支付申请
  [EApprovalProcessType.vehicleRegistration, 'VehicleRegistrationApproval'],
  // 车辆信息登记
  [EApprovalProcessType.vehicleContract, 'VehicleContractApproval'],
  // 车辆信息登记
  [EApprovalProcessType.personnelChange, 'PersonnelChangeApproval'],
  // 人事调动
  [EApprovalProcessType.dimission, 'DimissionApproval'],
  // 离职管理
  [EApprovalProcessType.contractManagement, 'ContractManagementApproval'],
  // 合同管理
  [EApprovalProcessType.leaveApplication, 'LeaveApplicationApproavl'],
  // 请假申请
  [EApprovalProcessType.officialApplication, 'OfficialApplicationApproavl'],
  // 公章申请
  [EApprovalProcessType.vehicleApplication, 'VehicleApplicationApproavl'],
  // 用车申请
  [EApprovalProcessType.businessApplication, 'BusinessApplication'],
  // 出差申请
  [EApprovalProcessType.payCertificate, 'CertificateApproval'],
  // 支付证明单
  [EApprovalProcessType.payMoney, 'PayMoneyApproval'],
  // 款项支付
  [EApprovalProcessType.receipt, 'ReceiptApproval'] // 收款管理
  ]);
  const noticeJump = data => {
    console.log('data', data);
    try {
      if (data.ext?.id && data.ext?.type_id) {
        if (approvalProcessTypeMap.has(data.ext?.type_id)) {
          const name = approvalProcessTypeMap.get(data.ext?.type_id);
          // window.sessionStorage.setItem(name, '1')
          router.push({
            name,
            query: {
              id: data.ext?.id,
              type_id: data.ext?.type_id,
              msg_id: data.msg_id,
              back: 1
            }
          });
        }
      } else {
        console.error('ext对象里的id为空');
      }
    } catch (err) {
      gp.$baseMessage(`没有权限，请联系管理员进行添加！`, 'error', 'vab-hey-message-error');
      console.error(err);
    }
  };
  // watch(
  //   () => route.name,
  //   (newVal, oldVal) => {
  //     if (newVal !== oldVal) {
  // const blackListName = ['Workbench', 'NoticeList']
  // if (!blackListName.includes(newVal as string)) {
  //   const timer = setTimeout(() => {
  //     // noticeStore.requestNoticeDataFn()
  //     clearTimeout(timer)
  //   }, 1500)
  // }
  //     }
  //   }
  // )
  return {
    noticeJump,
    approvalProcessTypeMap
  };
}