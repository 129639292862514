import request from '@/utils/request';
export function rosterList(data) {
    return request({
        url: '/account/getAccountList',
        method: 'post',
        data,
    });
}
//新增用户
export function addAccount(data) {
    return request({
        url: '/account/addAccount',
        method: 'post',
        data,
    });
}
//修改用户
export function editAccount(data) {
    return request({
        url: '/account/editAccount',
        method: 'post',
        data,
    });
}
//员工禁用、启用
export function setAccountStatus(data) {
    return request({
        url: '/account/setAccountStatus',
        method: 'post',
        data,
    });
}
//更新企业信息
export function setTenantInfo(data) {
    return request({
        url: '/dept/setTenantInfo',
        method: 'post',
        data,
    });
}
//获取企业信息
export function getTenantInfo() {
    return request({
        url: '/dept/getTenantInfo',
        method: 'POST',
    });
}
// 修改员工
export function updateAccountAPI(data) {
    return request({
        url: '/account/updateAccount',
        method: 'POST',
        data,
    });
}
//校验
export function accountValidateAPI(data) {
    return request({
        url: '/account/accountValidate',
        method: 'POST',
        data,
    });
}
//重置密码
export function adminResetUserPwdAPI(data) {
    return request({
        url: '/passport/adminResetUserPwd',
        method: 'POST',
        data,
    });
}
