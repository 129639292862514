import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-14ad672d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-app-main"
};
const _hoisted_2 = {
  class: "section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_router_view = _resolveComponent("vab-router-view");
  const _component_vab_footer = _resolveComponent("vab-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createVNode(_component_vab_router_view)]), _createVNode(_component_vab_footer)]);
}