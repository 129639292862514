import qs from 'qs';
import { resolve } from 'path';
import { hasPermission } from '@/utils/permission';
import { isExternal } from '@/utils/validate';
import { recordRoute } from '@/config';
import router from '@/router';
import { asyncRoutes } from '@/router/routes';
/**
 *
 * @param routes 拍扁前端配置路由
 */
export function flappingFontRoutes() {
    // asyncRoutes
    const routesMap = new Map([]);
    const _flappingFontRoutes = (routes) => {
        if (Array.isArray(routes)) {
            for (const route of routes) {
                if (route?.name) {
                    routesMap.set(route.name, route);
                    const children = route.children;
                    if (children) {
                        _flappingFontRoutes(children);
                    }
                }
            }
        }
    };
    _flappingFontRoutes(asyncRoutes);
    return routesMap;
}
function filterSplitChildren(arr) {
    const children = [];
    const btnChildren = [];
    for (const item of arr) {
        if (item.meta.type === 1) {
            children.push(item);
            continue;
        }
        if (item.meta.type === 2) {
            btnChildren.push(item);
        }
    }
    return {
        children,
        btnChildren
    };
}
/**
 *  根据后端路由去匹配前端路由，后端路由需要匹配title、icon、name、path
 * @param backRoutes 后端路由
 * @param flappingRoutes 拍扁的前端路由
 * @returns
 */
export function filterBackRoutes(backRoutes, flappingRoutes, parentPath = '', parent = {}) {
    const btnPermissions = []; // 按钮权限
    const _filterBackRoutes = (backRoutes, flappingRoutes, parentPath = '', parent = {}) => {
        return backRoutes.reduce((pre, cur) => {
            if (!cur.hidden) { // 这个后端的hidden是指是否有这个路由 1 是不存在 0 是存在
                const name = cur.name;
                if (flappingRoutes.has(name)) {
                    const fontRoute = flappingRoutes.get(name);
                    let meta = fontRoute.meta ?? {};
                    meta = Object.assign({}, meta, {
                        icon: cur.icon,
                        title: cur.type === 1 ? cur.title : fontRoute.meta.title,
                        type: cur.type,
                        sort: cur.sort
                    });
                    // 兼容旧数据，没有path的时候，使用name来作为path
                    let path = cur.path;
                    path = parentPath + path;
                    path = path.replace(/\/+/g, '/');
                    const children = cur.children;
                    if (name === 'ProjectOutputValueAdd') {
                        console.log('cur.type', cur.type);
                    }
                    if (cur.type === 2) {
                        // 按钮，有路径的，要修改激活上一级表单
                        if (cur.path) {
                            meta.activeMenu = parent.path;
                            // console.log('parent', parent)
                        }
                    }
                    const result = {
                        ...fontRoute,
                        path,
                        name,
                        meta,
                        children: []
                    };
                    if (!path) {
                        delete result.path;
                    }
                    if (Array.isArray(children) && children.length) {
                        const newChildren = _filterBackRoutes(children, flappingRoutes, path, result);
                        const res = filterSplitChildren(newChildren);
                        result.children.push(...res.children);
                        if (res.btnChildren?.length) {
                            parent.children?.push(...res.btnChildren);
                        }
                    }
                    if (!result.children.length) {
                        delete result.children;
                    }
                    else {
                        result.meta.isDir = true;
                    }
                    pre.push(result);
                }
                if (cur.type === 2) {
                    // 按钮， 有的按钮是有路径的， 有的是没有路径的
                    btnPermissions.push(name);
                }
            }
            return pre;
        }, []);
    };
    const routes = _filterBackRoutes(backRoutes, flappingRoutes, parentPath, parent);
    return {
        routes,
        btnPermissions
    };
}
/**
 * @description all模式渲染后端返回路由,支持包含views路径的所有页面
 * @param asyncRoutes
 * @returns {*}
 */
export function convertRouter(asyncRoutes) {
    return asyncRoutes.map((route) => {
        if (route.component) {
            const component = route.component.match(/^@\S+|^Layout$/);
            if (component)
                component[0] === 'Layout'
                    ? (route.component = () => import('@vab/layouts/index.vue'))
                    : (route.component = () => import(`@/${component[0].replace(/@\/*/, '')}.vue`));
            else
                throw `后端路由加载失败，请输入'Layout'或以'@/'开头的本地组件地址: ${route.component}`;
        }
        if (route.children)
            route.children.length
                ? (route.children = convertRouter(route.children))
                : delete route.children;
        return route;
    });
}
/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param rolesControl 是否进行权限控制
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(routes, rolesControl, baseUrl = '/') {
    return routes
        .filter((route) => rolesControl && route.meta.guard ? hasPermission(route.meta.guard) : true)
        .flatMap((route) => baseUrl !== '/' && route.children && route.meta.levelHidden
        ? [...route.children]
        : route)
        .map((route) => {
        route = { ...route };
        route.path =
            route.path !== '*' && !isExternal(route.path)
                ? resolve(baseUrl, route.path)
                : route.path;
        if (route.children && route.children.length > 0) {
            route.children = filterRoutes(route.children, rolesControl, route.path);
            if (route.children.length > 0) {
                route.childrenPathList = route.children.flatMap((_) => _.childrenPathList);
                if (!route.redirect) {
                    // const firstChild =  route.children[0];
                    const filterArr = route.children.filter(item => item.meta?.type === 1);
                    if (filterArr.length) {
                        const firstChild = filterArr[0];
                        route.redirect = firstChild.redirect || firstChild.path;
                    }
                }
            }
        }
        else
            route.childrenPathList = [route.path];
        return route;
    });
}
/**
 * 根据path路径获取matched
 * @param routes 菜单routes
 * @param path 路径
 * @returns {*} matched
 */
export function handleMatched(routes, path) {
    return routes
        .filter((route) => (route?.childrenPathList || []).indexOf(path) + 1)
        .flatMap((route) => route.children ? [route, ...handleMatched(route.children, path)] : [route]);
}
/**
 * 生成单个多标签元素，可用于同步/异步添加多标签
 * @param tag route页信息
 */
export function handleTabs(tag) {
    let parentIcon = null;
    if ('matched' in tag)
        for (let i = tag.matched.length - 2; i >= 0; i--)
            if (!parentIcon && tag.matched[i].meta.icon)
                parentIcon = tag.matched[i].meta.icon;
    if (!parentIcon)
        parentIcon = 'menu-line';
    const path = handleActivePath(tag, true);
    if (tag.name && tag.meta.tabHidden !== true)
        return {
            path,
            query: 'query' in tag ? tag.query : {},
            params: 'params' in tag ? tag.params : {},
            name: tag.name,
            parentIcon,
            meta: { ...tag.meta },
        };
}
/**
 * 根据当前route获取激活菜单
 * @param route 当前路由
 * @param isTab 是否是标签
 * @returns {string|*}
 */
export function handleActivePath(route, isTab = false) {
    const { meta, path } = route;
    const rawPath = route.matched
        ? route.matched[route.matched.length - 1].path
        : path;
    const fullPath = route.query && Object.keys(route.query).length
        ? `${route.path}?${qs.stringify(route.query)}`
        : route.path;
    if (isTab)
        return meta.dynamicNewTab ? fullPath : rawPath;
    if (meta.activeMenu)
        return meta.activeMenu;
    return fullPath;
}
/**
 * 获取当前跳转登录页的Route
 * @param currentPath 当前页面地址
 */
export function toLoginRoute(currentPath) {
    if (recordRoute && currentPath !== '/') {
        return {
            path: '/login',
            query: { redirect: currentPath },
            replace: true,
        };
    }
    else {
        return { path: '/login', replace: true };
    }
}
/**
 * 获取路由中所有的Name
 * @param routes 路由数组
 * @returns {*} Name数组
 */
export function getNames(routes) {
    return routes.flatMap((route) => {
        const names = [];
        if (route.name)
            names.push(route.name);
        if (route.children)
            names.push(...getNames(route.children));
        return names;
    });
}
/**
 * 修改路由标题title
 * @param title 修改之后的标题
 * @param targetTitle 目标路由name，不传递为当前路由
 */
import { useTabsStore } from '@/store/modules/tabs';
import { useRoutesStore } from '@/store/modules/routes';
export function setDocumentTitle(title, targetName) {
    const route = router.currentRoute.value;
    const { changeMenuMeta } = useRoutesStore();
    const { changeTabsMeta } = useTabsStore();
    changeTabsMeta({
        name: targetName ?? route.name,
        meta: {
            title,
        },
    });
    changeMenuMeta({
        name: targetName ?? route.name,
        meta: {
            title,
        },
    });
}
/**
 * 跳转到404页面，并清除tab标签
 */
export function redirectToNotFound() {
    closeAndRedirect({ name: '404' });
}
/**
 * 关闭当前页面，并清除tab标签，返回上一页
 */
export function closeAndGoBack() {
    closeAndRedirect();
    router.go(-1);
}
export function closeAndRedirect(pushConfig, flag = 'push') {
    const route = router.currentRoute.value;
    const tabsStore = useTabsStore();
    const { delVisitedRoute } = tabsStore;
    const detailPath = handleActivePath(route, true);
    pushConfig &&
        flag === 'push' &&
        router.push(pushConfig);
    pushConfig &&
        flag === 'replace' &&
        router.replace(pushConfig);
    delVisitedRoute(detailPath);
}
/**
 * 路由排序
 * @param children 路由数组
 * @returns
 */
export function handleMenuDataSort(children) {
    if (Array.isArray(children)) {
        for (const item of children) {
            bubbleSort(item.children);
        }
        return bubbleSort(children);
    }
    return [];
}
function bubbleSort(arr) {
    if (Array.isArray(arr)) {
        const len = arr.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len - i - 1; j++) {
                const cur = arr[j].meta?.sort ?? 999;
                const pre = arr[j + 1].meta?.sort ?? 999;
                if (cur > pre) {
                    const temp = arr[j];
                    arr[j] = arr[j + 1];
                    arr[j + 1] = temp;
                }
            }
        }
        return arr;
    }
    return [];
}
