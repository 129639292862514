/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl';
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { getUserInfo, socialLogin } from '@/api/user';
// import { getParamClassList } from '@/api/project'
import { getToken, removeToken, setToken } from '@/utils/token';
// import { getFormInfo } from '@/views/login/captcha'
import { resetRouter } from '@/router';
import { getTenantInfo } from '@/api/roster';
// import { isArray, isString } from '@/utils/validate'
import { tokenName } from '@/config';
import { gp } from '@gp';
export const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    id: '',
    username: '',
    avatar: '',
    company_name: '',
    contact: '',
    contact_phone: '',
    logo_url: '',
    limit_num: '',
    phone: '',
    city: '',
    address: '',
    province: '',
    county: '',
    business_license: '',
    business_license_url: '',
    tenants: '',
    tenant: '',
    param: '',
    backRoutes: '',
    tenant_code: '',
    recordLogoutAccount: '',
    permission: ''
  }),
  getters: {
    getToken: state => state.token,
    getUsername: state => state.username,
    getAvatar: state => state.avatar,
    getRoutes: state => state.backRoutes
  },
  actions: {
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken(token) {
      this.token = token;
      setToken(token);
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername(username) {
      this.username = username;
    },
    //公司名
    setCompany_name(company_name) {
      this.company_name = company_name;
    },
    //用户ID
    setId(id) {
      this.id = id;
    },
    //联系人
    setContact(contact) {
      this.contact = contact;
    },
    //联系人电话
    setContactPhone(contact_phone) {
      this.contact_phone = contact_phone;
    },
    //logo图片
    setLogo_url(logo_url) {
      this.logo_url = logo_url;
    },
    //员工数
    setLimit_num(limit_num) {
      this.limit_num = limit_num;
    },
    //手机号
    setPhone(phone) {
      this.phone = phone;
    },
    //城市
    setCity(city) {
      this.city = city;
    },
    //地址
    setAddress(address) {
      this.address = address;
    },
    //省份
    setProvince(province) {
      this.province = province;
    },
    setcounty(county) {
      this.county = county;
    },
    setBusiness_license(business_license) {
      this.business_license = business_license;
    },
    //营业执照图片
    setBusiness_license_url(business_license_url) {
      this.business_license_url = business_license_url;
    },
    //人员权限
    setPermission(permission) {
      this.permission = permission;
    },
    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar(avatar) {
      this.avatar = avatar;
    },
    setTenants(tenants) {
      this.tenants = tenants;
    },
    setTenant(tenant) {
      this.tenant = tenant;
    },
    setRoutes(backRoutes) {
      this.backRoutes = backRoutes;
    },
    setTenant_code(tenant_code) {
      this.tenant_code = tenant_code;
    },
    setVirtualRoles() {
      const aclStore = useAclStore();
      aclStore.setFull(true);
      this.setUsername('');
      this.setAvatar('');
    },
    // /**
    //  * @description 登录
    //  * @param {*} userInfo
    //  */
    // async login(userInfo: any) {
    //   getFormInfo(userInfo)
    // },
    // async cptLogin(userInfo: any) {
    //   getFormInfo(userInfo)
    // },
    /**
     * @description 设置token并发送提醒
     * @param {string} token 更新令牌
     * @param {string} tokenName 令牌名称
     */
    afterLogin(token, tokenName) {
      if (token) {
        this.setToken(token);
      } else {
        const err = `登录接口异常，未正确返回${tokenName}...`;
        gp.$baseMessage(err, 'error', 'vab-hey-message-error');
        throw err;
      }
    },
    /**
     * @description 第三方登录
     * @param {*} tokenData
     */
    async socialLogin(tokenData) {
      const {
        data: {
          [tokenName]: token
        }
      } = await socialLogin(tokenData);
      this.afterLogin(token, tokenName);
    },
    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    async getUserInfo() {
      const {
        result: {
          userInfo,
          routes,
          permissions
        }
      } = await getUserInfo();
      // console.log('userInfo', userInfo)
      this.setAccount(userInfo.account);
      this.setUsername(userInfo.name);
      this.setPhone(userInfo.phone);
      this.setLimit_num(userInfo.tenant.limit_num);
      this.setTenants(userInfo.tenants);
      this.setTenant(userInfo.tenant);
      this.setRoutes(routes);
      this.setPermission(permissions);
      this.setId(userInfo.id);
      this.setCompany_name(userInfo.tenant.company_name);
      const aclStore = useAclStore();
      aclStore.setPermission(permissions);
      getTenantInfo().then(res => {
        // @ts-ignore
        if (res.code === 200) {
          // @ts-ignore
          const tenant = res?.result?.tenant;
          this.setCompany_name(tenant.company_name);
          this.setCity(tenant.city);
          this.setAddress(tenant.address);
          this.setProvince(tenant.province);
          this.setcounty(tenant.county);
          this.setBusiness_license(tenant.business_license);
          this.setBusiness_license_url(tenant.business_license_url);
          this.setLogo_url(tenant.logo_url);
          this.setTenant_code(tenant.tenant_code);
          this.setContact(tenant.master?.name);
          this.setContactPhone(tenant.master?.phone);
        }
      });
      //console.log(routes)
      // const newRoures = []
      // const component = () => import('@/views/system/project/index.vue')
      // for (const item of routes) {
      //   if (item.name === 'system') {
      //     item.children.forEach((item) => {
      //       newRoures.push({
      //         name: item.name,
      //         path: `/system/${item.name}`,
      //         meta: {
      //           title: '水水水水',
      //         },
      //       })
      //     })
      //     console.log(item)
      //   }
      // }
      // console.log('newRoures', newRoures)
      // router.addRoute({
      //   path: '/system',
      //   component: Layout,
      //   meta: {
      //     title: '系统参数2',
      //     icon: 'blaze-line',
      //   },
      //   children: [
      //     {
      //       path: 'upload',
      //       name: 'upload',
      //       component: () => import('@/views/oa/upload/index.vue'),
      //       meta: {
      //         title: '文件上传',
      //         icon: 'home-2-line',
      //       },
      //     },
      //   ],
      // })
    },
    // async getParamClassList() {
    //   const {
    //     result: { items },
    //   } = await getParamClassList()
    //   // console.log(items)
    //   this.setParam(items)
    //   console.log(items)
    // },
    /**
     * @description 退出登录
     */
    async logout(reloadAfterTime) {
      // 退出登录时记住用户账号，要回显在登录输入框
      this.setRecordLogoutAccount(this.account);
      // await logout()
      await this.resetAll();
      if (reloadAfterTime) {
        const timer = setTimeout(() => {
          // 解决横向布局退出登录显示不全的bug
          location.reload();
          // router.push('/login')
          window.location.reload();
          clearTimeout(timer);
        }, reloadAfterTime);
      } else {
        // 解决横向布局退出登录显示不全的bug
        location.reload();
        // router.push('/login')
        window.location.reload();
      }
    },
    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    async resetAll() {
      this.setToken('');
      // this.setUsername('游客')
      // this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
      const aclStore = useAclStore();
      const routesStore = useRoutesStore();
      const tabsStore = useTabsStore();
      aclStore.setPermission([]);
      aclStore.setFull(false);
      aclStore.setRole([]);
      tabsStore.delAllVisitedRoutes();
      routesStore.clearRoutes();
      await resetRouter();
      removeToken();
    },
    setAccount(account) {
      this.account = account;
    },
    setRecordLogoutAccount(username) {
      if (username) {
        this.recordLogoutAccount = username;
        window.sessionStorage.setItem('recordLogoutAccount', username);
      }
    }
  }
});