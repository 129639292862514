import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f837ede"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-footer"
};
const _hoisted_2 = {
  key: 0,
  class: "beian",
  href: "https://beian.miit.gov.cn/#/Integrated/index",
  target: "_blank"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");
  return _openBlock(), _createElementBlock("footer", _hoisted_1, [_createTextVNode(" Copyright "), _createVNode(_component_vab_icon, {
    icon: "copyright-line"
  }), _createTextVNode(" 2017- " + _toDisplayString($setup.fullYear) + "广州束隆互联网科技有限公司 ", 1 /* TEXT */), $setup.beianShow ? (_openBlock(), _createElementBlock("a", _hoisted_2, " 粤ICP备2023097964号-1 ")) : _createCommentVNode("v-if", true)]);
}