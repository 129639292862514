/*
 * @Description:
 * @Version: 3.0
 * @Autor: LIN
 * @Date: 2023-09-02 17:25:42
 * @LastEditors: Seven
 * @LastEditTime: 2023-11-07 14:28:40
 */
/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import { useUserStore } from '@/store/modules/user';
import { useRoutesStore } from '@/store/modules/routes';
import { useSettingsStore } from '@/store/modules/settings';
import VabProgress from 'nprogress';
import 'nprogress/nprogress.css';
import getPageTitle from '@/utils/pageTitle';
import { toLoginRoute } from '@/utils/routes';
import { asyncRoutes } from './routes';
//水印文件
// import Watermark from '@/utils/watermark'
import { authentication, loginInterception, routesWhiteList, supportVisit, } from '@/config';
//水印的文字
// const store = useUserStore()
// // const username = store.getUsername
// const { company_name, username } = toRefs(store)
function findPath(routes, name, arr) {
    const findItem = routes.find((item) => item.name === name);
    if (findItem) {
        let path = `/${findItem.path}`;
        path = path.replace(/\/+/g, '/');
        arr.push(path);
        const children = findItem.children;
        if (children) {
            for (const item of children) {
                findPath(children, item.name, arr);
            }
        }
    }
}
const rootPath = [];
findPath(asyncRoutes, 'Root', rootPath);
export function setupPermissions(router) {
    VabProgress.configure({
        easing: 'ease',
        speed: 500,
        trickleSpeed: 200,
        showSpinner: false,
    });
    router.beforeEach(async (to, from, next) => {
        const { getTheme: { showProgressBar }, } = useSettingsStore();
        const { routes, setRoutes } = useRoutesStore();
        const { token, getUserInfo, setVirtualRoles, resetAll } = useUserStore();
        if (showProgressBar)
            VabProgress.start();
        let hasToken = token;
        if (!loginInterception)
            hasToken = true;
        if (hasToken) {
            if (routes.length) {
                //添加水印
                // const setWatermark = (value: any) => {
                //   //@ts-ignore
                //   Watermark.set(value)
                // }
                // // console.log('水印', company_name.value, username.value)
                // setWatermark(company_name.value + username.value)
                // 1. 判断是否是跳转到登录页的
                let hasRootRoute = true; // 是否有跟路由 因为有可能因为权限问题不会分配Root路由，然后比如登录的时候是跳转到Root的
                let path = '/';
                // 2. 判断routes中是否有这个根路径路由
                if (rootPath.includes('/')) {
                    const asyncRoutesArr = routes.filter((item) => !item.meta.isStatic);
                    hasRootRoute = asyncRoutesArr.some((item) => rootPath.includes(item.path)); // Root更路由有子路由的
                    // 3. 如果没有的话跳转到第一个路由
                    if (!hasRootRoute) {
                        if (asyncRoutesArr.length) {
                            path = asyncRoutesArr[0].path;
                        }
                        else {
                            path = '/403';
                        }
                    }
                }
                // 禁止已登录用户返回登录页
                if (to.path === '/login/') {
                    // next({ path: '/' })
                    next({ path });
                    if (showProgressBar)
                        VabProgress.done();
                }
                else {
                    if (!hasRootRoute && rootPath.includes(to.path)) {
                        next({ path });
                        return;
                    }
                    if (router.hasRoute(to.name)) {
                        // console.log('beforeEach', to)
                        // next({
                        //   name: to.name
                        // })
                        next();
                    }
                    else {
                        next({
                            name: '404',
                        });
                    }
                }
            }
            else {
                try {
                    if (loginInterception) {
                        await getUserInfo();
                    }
                    // config/setting.config.js loginInterception为false(关闭登录拦截时)时，创建虚拟角色
                    else
                        await setVirtualRoles();
                    // 根据路由模式获取路由并根据权限过滤
                    await setRoutes(authentication);
                    next({ ...to, replace: true });
                }
                catch (err) {
                    console.error('vue-admin-beautiful错误拦截:', err);
                    await resetAll();
                    next(toLoginRoute(to.path));
                }
            }
        }
        else {
            if (routesWhiteList.includes(to.path)) {
                // 设置游客路由(不需要可以删除)
                if (supportVisit && !routes.length) {
                    await setRoutes('visit');
                    next({ path: to.path, replace: true });
                }
                else
                    next();
            }
            else
                next(toLoginRoute(to.path));
        }
    });
    router.afterEach((to) => {
        document.title = getPageTitle(to.meta.title);
        if (VabProgress.status)
            VabProgress.done();
    });
}
