import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b7794ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "logo"
};
const _hoisted_2 = {
  class: "logoImgWrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-container", {
      ['logo-container-' + $setup.theme.layout]: true
    }])
  }, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createCommentVNode(" 使用自定义svg示例 "), _createCommentVNode(" <vab-icon v-if=\"logo\" :icon=\"logo\" is-custom-svg /> "), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
      src: $setup.logo,
      class: "logoImg"
    }, null, 8 /* PROPS */, ["src"])])]), _createElementVNode("span", {
      class: _normalizeClass(["title", {
        'hidden-xs-only': $setup.theme.layout === 'horizontal'
      }])
    }, _toDisplayString($setup.title), 3 /* TEXT, CLASS */)]),
    _: 1 /* STABLE */
  })], 2 /* CLASS */);
}