export var EApprovalTbas;
(function (EApprovalTbas) {
    EApprovalTbas["pedding"] = "Pedding";
    EApprovalTbas["initiated"] = "Initiated";
    EApprovalTbas["toMe"] = "ToMe";
})(EApprovalTbas || (EApprovalTbas = {}));
export var AdministrativeInforTbas;
(function (AdministrativeInforTbas) {
    AdministrativeInforTbas["newsAlert"] = "NewsAlert";
    AdministrativeInforTbas["knowledgManagement"] = "KnowledgManagement";
    AdministrativeInforTbas["corporateCulture"] = "CorporateCulture";
    AdministrativeInforTbas["conferenceManagement"] = "ConferenceManagement";
    AdministrativeInforTbas["officialDocument"] = "OfficialDocument";
})(AdministrativeInforTbas || (AdministrativeInforTbas = {}));
export var EMsgTbas;
(function (EMsgTbas) {
    EMsgTbas[EMsgTbas["all"] = 0] = "all";
    EMsgTbas[EMsgTbas["unread"] = 1] = "unread";
    EMsgTbas[EMsgTbas["read"] = 2] = "read";
})(EMsgTbas || (EMsgTbas = {}));
// 审批通知跳转类型
export var EApprovalJumpType;
(function (EApprovalJumpType) {
    EApprovalJumpType[EApprovalJumpType["excused"] = 1] = "excused";
    EApprovalJumpType[EApprovalJumpType["tenderAdd"] = 2] = "tenderAdd";
    EApprovalJumpType[EApprovalJumpType["procurement"] = 3] = "procurement";
    EApprovalJumpType[EApprovalJumpType["payment"] = 4] = "payment";
    EApprovalJumpType[EApprovalJumpType["partProcess"] = 5] = "partProcess";
    EApprovalJumpType[EApprovalJumpType["return"] = 6] = "return";
    EApprovalJumpType[EApprovalJumpType["sendACard"] = 7] = "sendACard";
    EApprovalJumpType[EApprovalJumpType["reimbursement"] = 8] = "reimbursement";
    EApprovalJumpType[EApprovalJumpType["partChangeAdded"] = 9] = "partChangeAdded";
    EApprovalJumpType[EApprovalJumpType["purchaseAccountsPayable"] = 10] = "purchaseAccountsPayable";
    EApprovalJumpType[EApprovalJumpType["lock"] = 11] = "lock";
    EApprovalJumpType[EApprovalJumpType["equipmentRepair"] = 12] = "equipmentRepair";
    EApprovalJumpType[EApprovalJumpType["materialAllocation"] = 13] = "materialAllocation";
    EApprovalJumpType[EApprovalJumpType["debitSlips"] = 14] = "debitSlips";
    EApprovalJumpType[EApprovalJumpType["manufacturerDeduction"] = 15] = "manufacturerDeduction";
    EApprovalJumpType[EApprovalJumpType["modifyProject"] = 16] = "modifyProject";
})(EApprovalJumpType || (EApprovalJumpType = {}));
