import { ref } from 'vue';
import { validateCondition } from '../utils/const';
import { useRoute } from 'vue-router';
export default {
  __name: 'addNode',
  props: {
    childNodeP: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:childNodeP'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    __expose();
    const route = useRoute();
    const props = __props;
    const emits = __emit;
    const visible = ref(false);
    const addType = type => {
      visible.value = false;
      if (type != 4) {
        let data;
        if (type == 1) {
          data = {
            nodeName: '审批人',
            error: true,
            type: 1,
            settype: 4,
            selectMode: 0,
            selectRange: 0,
            directorLevel: 1,
            examineMode: 1,
            noHanderAction: 1,
            examineEndDirectorLevel: 0,
            childNode: props.childNodeP,
            nodeUserList: []
          };
        } else if (type == 2) {
          data = {
            nodeName: '抄送人',
            type: 2,
            ccSelfSelectFlag: 1,
            childNode: props.childNodeP,
            nodeUserList: []
          };
        }
        emits('update:childNodeP', data);
      } else {
        emits('update:childNodeP', {
          nodeName: '路由',
          type: 4,
          childNode: null,
          conditionNodes: [{
            nodeName: '条件1',
            error: true,
            type: 3,
            priorityLevel: 1,
            conditionList: [],
            nodeUserList: [],
            childNode: props.childNodeP
          }, {
            nodeName: '条件2',
            type: 3,
            // error: true,

            priorityLevel: 2,
            conditionList: [],
            nodeUserList: [],
            childNode: null
          }]
        });
      }
    };
    const __returned__ = {
      route,
      props,
      emits,
      visible,
      addType,
      ref,
      get validateCondition() {
        return validateCondition;
      },
      get useRoute() {
        return useRoute;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};