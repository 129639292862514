import request from '@/utils/request';
export function getList() {
    return request({
        url: '/tenant/getList',
        method: 'post',
    });
}
// 获取消息列表
export function getMessageList(data) {
    return request({
        url: '/message/getMessageList',
        method: 'post',
        data,
    });
}
// 设置全部消息为已读
export function setMessageStatus() {
    return request({
        url: '/message/setMessageStatus',
        method: 'post',
    });
}
