import Layout from '@vab/layouts/index.vue';
import { EApprovalDuties } from '@/enums/approval';
export const constantRoutes = [
    {
        path: '/setting',
        name: 'Configuration',
        component: Layout,
        meta: {
            title: '配置',
            hidden: true,
            icon: 'settings-5-line',
            // guard: ['Configuration'],
            isStatic: true,
        },
        children: [
            {
                path: 'personalCenter',
                name: 'PersonalCenter',
                component: () => import('@/views/setting/personalCenter/index.vue'),
                meta: {
                    title: '修改密码',
                    icon: 'map-pin-user-line',
                    // guard: ['PersonalCenter'],
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/callback',
        name: 'Callback',
        component: () => import('@/views/callback/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/403.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    // { path: '/:pathMatch(.*)*',
    //   name: 'PathMatch',
    //   redirect: "/404",
    //   meta: {
    //     hidden: true,
    //     isStatic: true
    //   }
    // },
];
export const asyncRoutes = [
    {
        path: '/',
        name: 'Root',
        component: Layout,
        meta: {
            title: '首页',
            icon: 'home-2-line',
            guard: ['Root'],
        },
        children: [
            {
                path: 'workbench',
                name: 'Workbench',
                // component: () => import('@/views/index/workbench.vue'),
                component: () => import('@/views/index/notice/index.vue'),
                meta: {
                    title: '工作台',
                    // dot: true,
                    noClosable: true,
                    guard: ['Workbench'],
                },
            },
            {
                path: 'dataPlatform',
                name: 'DataPlatform',
                // component: () => import('@/views/index/workbench.vue'),
                component: () => import('@/views/index/dataPlatform/index.vue'),
                meta: {
                    title: '看板数据台',
                    // dot: true,
                    noClosable: true,
                    guard: ['DataPlatform'],
                },
            },
            {
                path: 'approvalList',
                name: 'ApprovalList',
                component: () => import('@/views/index/notice/approvalList.vue'),
                meta: {
                    activeMenu: '/approval-list',
                    title: '我的审批',
                    guard: ['ApprovalList'],
                },
            },
            {
                path: 'notice-list',
                name: 'NoticeList',
                component: () => import('@/views/index/notice/noticeList.vue'),
                meta: {
                    activeMenu: '/notice-list',
                    title: '消息通知',
                    guard: ['NoticeList'],
                },
            },
        ],
    },
    {
        path: '/tender',
        name: 'Tender',
        component: Layout,
        meta: {
            title: '投标管理',
            icon: 'blaze-line',
            guard: ['Tender'],
        },
        children: [
            {
                path: 'projectList',
                name: 'ProjectList',
                component: () => import('@/views/tender/projectList/index.vue'),
                meta: {
                    title: '项目立项',
                    noKeepAlive: true,
                    guard: ['ProjectList'],
                },
            },
            {
                path: 'projectContent',
                name: 'ProjectContent',
                component: () => import('@/views/tender/projectList/addProject.vue'),
                meta: {
                    title: '项目编辑页',
                    activeMenu: '/tender/projectList',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProjectContent'],
                },
            },
            {
                path: 'biddingCostManagement',
                name: 'BiddingCostManagement',
                component: () => import('@/views/tender/costManagement/index.vue'),
                meta: {
                    title: '投标成本（对内）',
                    noKeepAlive: true,
                    guard: ['BiddingCostManagement'],
                },
            },
            {
                path: 'costManagementDetail',
                name: 'CostManagementDetail',
                component: () => import('@/views/tender/costManagement/addCostManagement.vue'),
                meta: {
                    title: '投标成本编辑页',
                    activeMenu: '/tender/biddingCostManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['CostManagementDetail'],
                },
            },
            {
                path: 'costChanges',
                name: 'CostChanges',
                component: () => import('@/views/tender/costChanges/index.vue'),
                meta: {
                    title: '投标成本变更',
                    noKeepAlive: true,
                    guard: ['CostChanges'],
                },
            },
            {
                path: 'costChangesContent',
                name: 'CostChangesContent',
                component: () => import('@/views/tender/costChanges/content.vue'),
                meta: {
                    title: '投标成本变更编辑页',
                    activeMenu: '/tender/costChanges',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['CostChangesContent'],
                },
            },
            {
                path: 'budgetManagement',
                name: 'BudgetManagement',
                component: () => import('@/views/tender/budgetManagement/index.vue'),
                meta: {
                    title: '投标预算（对外）',
                    noKeepAlive: true,
                    guard: ['BudgetManagement'],
                },
            },
            {
                path: 'budgetDetail',
                name: 'BudgetDetail',
                component: () => import('@/views/tender/budgetManagement/budgetContent.vue'),
                meta: {
                    title: '投标预算编辑页',
                    activeMenu: '/tender/budgetManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BudgetDetail'],
                },
            },
            {
                path: 'budgetChanges',
                name: 'BudgetChanges',
                component: () => import('@/views/tender/budgetChanges/index.vue'),
                meta: {
                    title: '投标预算变更',
                    noKeepAlive: true,
                    guard: ['BudgetChanges'],
                },
            },
            {
                path: 'budgetChangesContent',
                name: 'BudgetChangesContent',
                component: () => import('@/views/tender/budgetChanges/content.vue'),
                meta: {
                    title: '投标预算变更编辑页',
                    activeMenu: '/tender/budgetChanges',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BudgetChangesContent'],
                },
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import('@/views/tender/profile/index.vue'),
                meta: {
                    title: '竞争对手档案',
                    noKeepAlive: true,
                    guard: ['Profile'],
                },
            },
            {
                path: 'profileContent',
                name: 'ProfileContent',
                component: () => import('@/views/tender/profile/profileContent.vue'),
                meta: {
                    title: '竞争对手档案编辑页',
                    activeMenu: '/tender/profile',
                    hidden: true,
                    guard: ['ProfileContent'],
                    noKeepAlive: false,
                    dynamicNewTab: true,
                },
            },
            {
                path: 'siteInvestigation',
                name: 'SiteInvestigation',
                component: () => import('@/views/tender/siteInvestigation/index.vue'),
                meta: {
                    title: '现场勘察',
                    noKeepAlive: true,
                    guard: ['SiteInvestigation'],
                },
            },
            {
                path: 'siteInvestigationContent',
                name: 'SiteInvestigationContent',
                component: () => import('@/views/tender/siteInvestigation/siteInvestigationContent.vue'),
                meta: {
                    title: '现场勘察编辑页',
                    activeMenu: '/tender/siteInvestigation',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['SiteInvestigationContent'],
                },
            },
            {
                path: 'contractDoc',
                name: 'ContractDoc',
                component: () => import('@/views/tender/projectContract/index.vue'),
                meta: {
                    title: '项目合同',
                    noKeepAlive: true,
                    guard: ['ContractDoc'],
                },
            },
            {
                path: 'addContract',
                name: 'AddContract',
                component: () => import('@/views/tender/projectContract/addContract.vue'),
                meta: {
                    title: '新增项目合同',
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/tender/contractDoc',
                    guard: ['AddContract'],
                },
            },
            {
                path: 'contractDetail',
                name: 'ContractDetail',
                component: () => import('@/views/tender/projectContract/addContract.vue'),
                meta: {
                    title: '项目合同详情',
                    activeMenu: '/tender/contractDoc',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ContractDetail'],
                    // icon: 'remixicon-line',
                },
            },
        ],
    },
    {
        path: '/projectPlanning',
        name: 'ProjectPlanning',
        component: Layout,
        meta: {
            title: '项目规划',
            icon: 'blaze-line',
            guard: ['ProjectPlanning'],
        },
        children: [
            {
                path: 'targetCosting',
                name: 'TargetCosting',
                component: () => import('@/views/projectPlanning/targetCosting/index.vue'),
                meta: {
                    title: '目标成本编制',
                    noKeepAlive: true,
                    guard: ['TargetCosting'],
                },
            },
            {
                path: 'targetCostingContent',
                name: 'TargetCostingContent',
                component: () => import('@/views/projectPlanning/targetCosting/content.vue'),
                meta: {
                    activeMenu: '/projectPlanning/targetCosting',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '目标成本编制编辑页',
                    guard: ['TargetCostingContent'],
                },
            },
            {
                path: 'targetCostingDetail',
                name: 'TargetCostingDetail',
                component: () => import('@/views/projectPlanning/targetCosting/detail.vue'),
                meta: {
                    activeMenu: '/projectPlanning/targetCosting',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '目标成本编制详情页',
                    guard: ['TargetCostingDetail'],
                },
            },
            {
                path: 'targetCostingApproval',
                name: 'TargetCostingApproval',
                component: () => import('@/views/projectPlanning/targetCosting/approval.vue'),
                meta: {
                    activeMenu: '/projectPlanning/targetCosting',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '目标成本编制审批页',
                    guard: ['TargetCostingApproval'],
                },
            },
            //////////////////////////////////
            {
                path: 'wbsProject',
                name: 'WBSProject',
                component: () => import('@/views/projectPlanning/wbsProject/index.vue'),
                meta: {
                    title: '项目WBS',
                    noKeepAlive: true,
                    guard: ['WBSProject'],
                },
            },
            {
                path: 'wbsProjectContent',
                name: 'WBSProjectContent',
                component: () => import('@/views/projectPlanning/wbsProject/content.vue'),
                meta: {
                    activeMenu: '/projectPlanning/wbsProject',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '项目WBS编辑页',
                    guard: ['WBSProjectContent'],
                },
            },
            {
                path: 'wbsProjectDetail',
                name: 'WBSProjectDetail',
                component: () => import('@/views/projectPlanning/wbsProject/detail.vue'),
                meta: {
                    activeMenu: '/projectPlanning/wbsProject',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '项目WBS详情页',
                    guard: ['WBSProjectDetail'],
                },
            },
            {
                path: 'wbsProjectApproval',
                name: 'WBSProjectApproval',
                component: () => import('@/views/projectPlanning/wbsProject/approval.vue'),
                meta: {
                    activeMenu: '/projectPlanning/wbsProject',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '项目WBS审批页',
                    guard: ['WBSProjectApproval'],
                },
            },
            {
                path: 'materiaPlan',
                name: 'MateriaPlan',
                component: () => import('@/views/projectPlanning/materiaPlan/index.vue'),
                meta: {
                    title: '材料总计划',
                    noKeepAlive: true,
                    guard: ['MateriaPlan'],
                },
            },
            {
                path: 'materiaPlanContent',
                name: 'MateriaPlanContent',
                component: () => import('@/views/projectPlanning/materiaPlan/content.vue'),
                meta: {
                    activeMenu: '/projectPlanning/materiaPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料总计划编辑页',
                    guard: ['MateriaPlanContent'],
                },
            },
            {
                path: 'materiaPlanDetail',
                name: 'MateriaPlanDetail',
                component: () => import('@/views/projectPlanning/materiaPlan/detail.vue'),
                meta: {
                    activeMenu: '/projectPlanning/materiaPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料总计划详情页',
                    guard: ['MateriaPlanDetail'],
                },
            },
            {
                path: 'materiaPlanApproval',
                name: 'MateriaPlanApproval',
                component: () => import('@/views/projectPlanning/materiaPlan/approval.vue'),
                meta: {
                    activeMenu: '/projectPlanning/materiaPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料总计划页',
                    guard: ['materiaPlanApproval'],
                },
            },
            {
                path: 'equipmentPlan',
                name: 'EquipmentPlan',
                component: () => import('@/views/projectPlanning/equipmentPlan/index.vue'),
                meta: {
                    title: '设备总计划',
                    noKeepAlive: true,
                    guard: ['EquipmentPlan'],
                },
            },
            {
                path: 'equipmentPlanContent',
                name: 'EquipmentPlanContent',
                component: () => import('@/views/projectPlanning/equipmentPlan/content.vue'),
                meta: {
                    activeMenu: '/projectPlanning/equipmentPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '设备总计划编辑页',
                    guard: ['EquipmentPlanContent'],
                },
            },
            {
                path: 'equipmentPlanDetail',
                name: 'EquipmentPlanDetail',
                component: () => import('@/views/projectPlanning/equipmentPlan/detail.vue'),
                meta: {
                    activeMenu: '/projectPlanning/equipmentPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '设备总计划详情页',
                    guard: ['EquipmentPlanDetail'],
                },
            },
            {
                path: 'equipmentPlanApproval',
                name: 'EquipmentPlanApproval',
                component: () => import('@/views/projectPlanning/equipmentPlan/approval.vue'),
                meta: {
                    activeMenu: '/projectPlanning/equipmentPlan',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '设备总计划页',
                    guard: ['EquipmentPlanApproval'],
                },
            },
        ],
    },
    {
        path: '/projectData',
        name: 'ProjectData',
        component: Layout,
        meta: {
            title: '项目数据',
            icon: 'database-line',
            guard: ['ProjectData'],
        },
        children: [
            {
                path: 'participationMode',
                name: 'ParticipationMode',
                component: () => import('@/views/projectData/participationMode/index.vue'),
                meta: {
                    title: '参建模式',
                    noKeepAlive: true,
                    guard: ['ParticipationMode'],
                },
            },
            {
                path: 'contractingMethod',
                name: 'ContractingMethod',
                component: () => import('@/views/projectData/contractingMethod/index.vue'),
                meta: {
                    title: '承包方式',
                    noKeepAlive: true,
                    guard: ['ContractingMethod'],
                },
            },
            {
                path: 'projectScale',
                name: 'ProjectScale',
                component: () => import('@/views/projectData/projectScale/index.vue'),
                meta: {
                    title: '项目规模',
                    noKeepAlive: true,
                    guard: ['ProjectScale'],
                },
            },
        ],
    },
    {
        path: '/construction',
        name: 'Construction',
        component: Layout,
        meta: {
            title: '施工管理',
            icon: 'article-line',
            guard: ['Construction'],
        },
        children: [
            {
                path: 'dataManagement',
                name: 'DataManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '资料管理',
                    guard: ['DataManagement'],
                },
                children: [
                    {
                        path: 'contractCompilation',
                        name: 'ContractCompilation',
                        component: () => import('@/views/construction/dataManagement/contractCompilation/index.vue'),
                        meta: {
                            title: '合同汇编',
                            guard: ['ContractCompilation'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.dataManagement,
                        },
                    },
                    {
                        path: 'contractCompilationContent',
                        name: 'ContractCompilationContent',
                        component: () => import('@/views/construction/dataManagement/contractCompilation/content.vue'),
                        meta: {
                            title: '合同汇编详情',
                            activeMenu: '/construction/dataManagement/contractCompilation',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: false,
                            guard: ['ContractCompilationContent'],
                            dutiesId: EApprovalDuties.dataManagement,
                        },
                    },
                    {
                        path: 'processData',
                        name: 'ProcessData',
                        component: () => import('@/views/construction/dataManagement/processData/index.vue'),
                        meta: {
                            title: '过程资料管理',
                            guard: ['ProcessData'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.dataManagement,
                        },
                    },
                    {
                        path: 'processDataContent',
                        name: 'ProcessDataContent',
                        component: () => import('@/views/construction/dataManagement/processData/content.vue'),
                        meta: {
                            title: '过程资料管理详情',
                            activeMenu: '/construction/dataManagement/processData',
                            hidden: true,
                            guard: ['ProcessDataContent'],
                            noKeepAlive: false,
                            dutiesId: EApprovalDuties.dataManagement,
                            dynamicNewTab: true,
                        },
                    },
                    {
                        path: `projectCollection`,
                        name: 'ProjectCollection',
                        component: () => import('@/views/construction/dataManagement/projectCollection/index.vue'),
                        meta: {
                            title: '项目请款台账',
                            noKeepAlive: true,
                            guard: ['ProjectCollection'],
                            dutiesId: EApprovalDuties.dataManagement,
                        },
                    },
                    {
                        path: 'projectCollectionDetail',
                        name: 'ProjectCollectionDetail',
                        component: () => import('@/views/construction/dataManagement/projectCollection/content.vue'),
                        meta: {
                            title: '项目请款详情',
                            activeMenu: '/construction/dataManagement/projectCollection',
                            hidden: true,
                            guard: ['ProjectCollectionDetail'],
                            dutiesId: EApprovalDuties.dataManagement,
                            dynamicNewTab: true,
                        },
                    },
                    {
                        path: 'projectPayment',
                        name: 'ProjectPayment',
                        component: () => import('@/views/construction/dataManagement/projectPayment/index.vue'),
                        meta: {
                            title: '项目付款台账',
                            noKeepAlive: true,
                            guard: ['ProjectPayment'],
                            dutiesId: EApprovalDuties.dataManagement,
                        },
                    },
                    {
                        path: 'projectPaymentContent',
                        name: 'ProjectPaymentContent',
                        component: () => import('@/views/construction/dataManagement/projectPayment/content.vue'),
                        meta: {
                            title: '项目付款台账详情',
                            activeMenu: '/construction/dataManagement/projectPayment',
                            hidden: true,
                            guard: ['ProjectPaymentContent'],
                            dutiesId: EApprovalDuties.dataManagement,
                            dynamicNewTab: true,
                        },
                    },
                ],
            },
            {
                path: 'costManagement',
                name: 'CostManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '造价管理',
                    guard: ['CostManagement'],
                },
                children: [
                    {
                        path: 'projectCost',
                        name: 'ProjectCost',
                        component: () => import('@/views/construction/costManagement/projectCost/index.vue'),
                        meta: {
                            title: '施工成本分析',
                            noKeepAlive: true,
                            guard: ['ProjectCost'],
                        },
                    },
                    {
                        path: 'projectCostDetail',
                        name: 'ProjectCostDetail',
                        component: () => import('@/views/construction/costManagement/projectCost/addProjectCost.vue'),
                        meta: {
                            title: '施工成本编辑页',
                            activeMenu: '/construction/costManagement/projectCost',
                            hidden: true,
                            guard: ['ProjectCostDetail'],
                            dutiesId: EApprovalDuties.costManagement,
                            dynamicNewTab: true,
                        },
                    },
                    // {
                    //   path: 'partAnalysis',
                    //   name: 'PartAnalysis',
                    //   component: () =>
                    //     import(
                    //       '@/views/construction/costManagement/projectCost/partAnalysis.vue'
                    //     ),
                    //   meta: {
                    //     title: '部位分析详情',
                    //     activeMenu: '/construction/costManagement/projectCost',
                    //     hidden: true,
                    //     guard: ['PartAnalysis'],
                    //     dutiesId: EApprovalDuties.costManagement,
                    //     dynamicNewTab: true,
                    //     // icon: 'remixicon-line',
                    //   },
                    // },
                    {
                        path: 'conCostChanges',
                        name: 'ConCostChanges',
                        component: () => import('@/views/construction/costManagement/conCostChanges/index.vue'),
                        meta: {
                            title: '施工成本变更',
                            guard: ['ConCostChanges'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.costManagement,
                        },
                    },
                    {
                        path: 'conCostChangesContent',
                        name: 'ConCostChangesContent',
                        component: () => import('@/views/construction/costManagement/conCostChanges/content.vue'),
                        meta: {
                            title: '施工成本变更编辑页',
                            activeMenu: '/construction/costManagement/conCostChanges',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['CostChangesContent'],
                            dutiesId: EApprovalDuties.costManagement,
                        },
                    },
                    {
                        path: 'projectOutputValue',
                        name: 'ProjectOutputValue',
                        component: () => import('@/views/construction/costManagement/projectOutputValue/index.vue'),
                        meta: {
                            title: '项目产值',
                            noKeepAlive: true,
                            guard: ['ProjectOutputValue'],
                            dutiesId: EApprovalDuties.costManagement,
                        },
                    },
                    {
                        path: 'projectOutputValueContent',
                        name: 'ProjectOutputValueContent',
                        component: () => import('@/views/construction/costManagement/projectOutputValue/content.vue'),
                        meta: {
                            title: '项目产值详情',
                            activeMenu: '/construction/costManagement/projectOutputValue',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['ProjectOutputValueContent'],
                            dutiesId: EApprovalDuties.costManagement,
                            noKeepAlive: false,
                            // icon: 'remixicon-line',
                        },
                    },
                    {
                        path: 'teamSettlement',
                        name: 'TeamSettlement',
                        component: () => import('@/views/construction/costManagement/teamSettlement/index.vue'),
                        meta: {
                            title: '班组结算',
                            noKeepAlive: true,
                            guard: ['TeamSettlement'],
                            dutiesId: EApprovalDuties.costManagement,
                        },
                    },
                    {
                        path: 'teamSettlementContent',
                        name: 'TeamSettlementContent',
                        component: () => import('@/views/construction/costManagement/teamSettlement/content.vue'),
                        meta: {
                            title: '班组结算详情',
                            activeMenu: '/construction/costManagement/teamSettlement',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['TeamSettlementContent'],
                            dutiesId: EApprovalDuties.costManagement,
                        },
                    },
                ],
            },
            {
                path: 'sceneManage',
                name: 'SceneManage',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '现场管理',
                    guard: ['SceneManage'],
                },
                children: [
                    {
                        path: 'drawingReview',
                        name: 'DrawingReview',
                        component: () => import('@/views/construction/sceneManage/drawingReview/index.vue'),
                        meta: {
                            title: '施工图审',
                            guard: ['DrawingReview'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    {
                        path: 'drawingReviewContent',
                        name: 'DrawingReviewContent',
                        component: () => import('@/views/construction/sceneManage/drawingReview/content.vue'),
                        meta: {
                            title: '施工图审详情',
                            activeMenu: '/construction/sceneManage/drawingReview',
                            hidden: true,
                            guard: ['DrawingReviewContent'],
                            dynamicNewTab: true,
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    {
                        path: 'technical',
                        name: 'Technical',
                        component: () => import('@/views/construction/sceneManage/technical/index.vue'),
                        meta: {
                            title: '施工图审技术交底',
                            guard: ['Technical'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    {
                        path: 'technicalContent',
                        name: 'TechnicalContent',
                        component: () => import('@/views/construction/sceneManage/technical/content.vue'),
                        meta: {
                            title: '施工图审技术交底详情',
                            activeMenu: '/construction/sceneManage/technical',
                            hidden: true,
                            guard: ['TechnicalContent'],
                            dutiesId: EApprovalDuties.sceneManage,
                            dynamicNewTab: true,
                        },
                    },
                    {
                        path: 'programme',
                        name: 'Programme',
                        component: () => import('@/views/construction/sceneManage/programme/index.vue'),
                        meta: {
                            title: '施工组织方案',
                            guard: ['Programme'],
                            noKeepAlive: true,
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    {
                        path: 'programmeContent',
                        name: 'ProgrammeContent',
                        component: () => import('@/views/construction/sceneManage/programme/content.vue'),
                        meta: {
                            title: '施工组织方案详情',
                            activeMenu: '/construction/sceneManage/programme',
                            hidden: true,
                            guard: ['ProgrammeContent'],
                            dutiesId: EApprovalDuties.sceneManage,
                            dynamicNewTab: true,
                        },
                    },
                    {
                        path: 'schedule',
                        name: 'Schedule',
                        component: () => import('@/views/construction/sceneManage/schedule/index.vue'),
                        meta: {
                            title: '施工进度',
                            noKeepAlive: true,
                            guard: ['Schedule'],
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    {
                        path: 'progressDetail',
                        name: 'ProgressDetail',
                        component: () => import('@/views/construction/sceneManage/schedule/content.vue'),
                        meta: {
                            title: '设定进度详情',
                            activeMenu: '/construction/sceneManage/schedule',
                            hidden: true,
                            guard: ['ProgressDetail'],
                            dutiesId: EApprovalDuties.sceneManage,
                            dynamicNewTab: true,
                        },
                    },
                    {
                        path: 'constructionLog',
                        name: 'ConstructionLog',
                        component: () => import('@/views/construction/sceneManage/constructionLog/index.vue'),
                        meta: {
                            title: '施工日志',
                            noKeepAlive: true,
                            guard: ['ConstructionLog'],
                            dutiesId: EApprovalDuties.sceneManage,
                        },
                    },
                    // {
                    //   path: 'addConstructionLog',
                    //   name: 'AddConstructionLog',
                    //   component: () =>
                    //     import(
                    //       '@/views/construction/sceneManage/constructionLog/content.vue'
                    //     ),
                    //   meta: {
                    //     title: '新增施工日志',
                    //     activeMenu: '/construction/sceneManage/constructionLog',
                    //     hidden: true,
                    //     guard: ['AddConstructionLog'],
                    //     dutiesId: EApprovalDuties.sceneManage,
                    //     dynamicNewTab: true,
                    //     noKeepAlive: true,
                    //   },
                    // },
                    {
                        path: 'constructionLogDetail',
                        name: 'ConstructionLogDetail',
                        component: () => import('@/views/construction/sceneManage/constructionLog/content.vue'),
                        meta: {
                            title: '施工日志详情',
                            activeMenu: '/construction/sceneManage/constructionLog',
                            hidden: true,
                            guard: ['ConstructionLogDetail'],
                            dutiesId: EApprovalDuties.sceneManage,
                            dynamicNewTab: true,
                        },
                    },
                ],
            },
            {
                path: 'qualityManage',
                name: 'QualityManage',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '质量管理',
                    guard: ['QualityManage'],
                },
                children: [
                    {
                        path: 'conTechDisclosure',
                        name: 'ConTechDisclosure',
                        component: () => import('@/views/construction/qualityManage/conTechDisclosure/index.vue'),
                        meta: {
                            title: '施工技术交底',
                            noKeepAlive: true,
                            guard: ['ConTechDisclosure'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                    {
                        path: 'conTechContent',
                        name: 'ConTechContent',
                        component: () => import('@/views/construction/qualityManage/conTechDisclosure/conTechContent.vue'),
                        meta: {
                            title: '施工技术交底编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/qualityManage/conTechDisclosure',
                            guard: ['ConTechContent'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                    {
                        path: 'qualityInspection',
                        name: 'QualityInspection',
                        component: () => import('@/views/construction/qualityManage/qualityInspection/index.vue'),
                        meta: {
                            title: '质量检查',
                            noKeepAlive: true,
                            guard: ['QualityInspection'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                    {
                        path: 'inspectionContent',
                        name: 'InspectionContent',
                        component: () => import('@/views/construction/qualityManage/qualityInspection/inspectionContent.vue'),
                        meta: {
                            title: '质量检查编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/qualityManage/qualityInspection',
                            guard: ['InspectionContent'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                    {
                        path: 'qualityRectification',
                        name: 'QualityRectification',
                        component: () => import('@/views/construction/qualityManage/qualityRectification/index.vue'),
                        meta: {
                            title: '质量整改',
                            noKeepAlive: true,
                            guard: ['QualityRectification'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                    {
                        path: 'rectificationContent',
                        name: 'RectificationContent',
                        component: () => import('@/views/construction/qualityManage/qualityRectification/rectificationContent.vue'),
                        meta: {
                            title: '质量整改编辑页',
                            hidden: true,
                            activeMenu: '/construction/qualityManage/qualityRectification',
                            dynamicNewTab: true,
                            guard: ['RectificationContent'],
                            dutiesId: EApprovalDuties.qualityManage,
                        },
                    },
                ],
            },
            {
                path: 'safetyManagement',
                name: 'SafetyManagement',
                meta: {
                    title: '安全管理',
                    guard: ['SafetyManagement'],
                },
                children: [
                    {
                        path: 'securityLog',
                        name: 'SecurityLog',
                        component: () => import('@/views/construction/safetyManagement/securityLog/index.vue'),
                        meta: {
                            title: '安全日志',
                            noKeepAlive: true,
                            guard: ['SecurityLog'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'securityLogContent',
                        name: 'SecurityLogContent',
                        component: () => import('@/views/construction/safetyManagement/securityLog/securityLogContent.vue'),
                        meta: {
                            title: '安全日志编辑页',
                            activeMenu: '/construction/safetyManagement/securityLog',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['SecurityLogContent'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'safetyTechDisclosure',
                        name: 'SafetyTechDisclosure',
                        component: () => import('@/views/construction/safetyManagement/safetyTechDisclosure/index.vue'),
                        meta: {
                            title: '安全技术交底',
                            noKeepAlive: true,
                            guard: ['SafetyTechDisclosure'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'safetyTechContent',
                        name: 'SafetyTechContent',
                        component: () => import('@/views/construction/safetyManagement/safetyTechDisclosure/safetyTechContent.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/safetyManagement/safetyTechDisclosure',
                            title: '安全技术交底编辑页',
                            guard: ['SafetyTechContent'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'securityCheck',
                        name: 'SecurityCheck',
                        component: () => import('@/views/construction/safetyManagement/securityCheck/index.vue'),
                        meta: {
                            title: '安全检查',
                            noKeepAlive: true,
                            guard: ['SecurityCheck'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'securityCheckContent',
                        name: 'SecurityCheckContent',
                        component: () => import('@/views/construction/safetyManagement/securityCheck/securityCheckContent.vue'),
                        meta: {
                            title: '安全检查编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/safetyManagement/securityCheck',
                            guard: ['SecurityCheckContent'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'safetyRectification',
                        name: 'SafetyRectification',
                        component: () => import('@/views/construction/safetyManagement/safetyRectification/index.vue'),
                        meta: {
                            title: '安全整改',
                            noKeepAlive: true,
                            guard: ['SafetyRectification'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    {
                        path: 'safetyRectiContent',
                        name: 'SafetyRectiContent',
                        component: () => import('@/views/construction/safetyManagement/safetyRectification/safetyRectiContent.vue'),
                        meta: {
                            title: '安全整改编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/safetyManagement/safetyRectification',
                            guard: ['SafetyRectiContent'],
                            dutiesId: EApprovalDuties.safetyManagement,
                        },
                    },
                    // {
                    //   path: 'thirdEducation',
                    //   name: 'ThirdEducation',
                    //   component: () =>
                    //     import(
                    //       '@/views/construction/safetyManagement/thirdEducation/index.vue'
                    //     ),
                    //   meta: {
                    //     title: '三级教育',
                    //     guard: ['ThirdEducation'],
                    //   },
                    // },
                ],
            },
            {
                path: 'materialManagement',
                name: 'MaterialManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '材料管理',
                    guard: ['MaterialManagement'],
                },
                children: [
                    {
                        path: 'materialRequisition',
                        name: 'MaterialRequisition',
                        component: () => import('@/views/construction/materialManagement/materialRequisition/index.vue'),
                        meta: {
                            title: '材料请购',
                            noKeepAlive: true,
                            guard: ['MaterialRequisition'],
                        },
                    },
                    {
                        path: 'materialContent',
                        name: 'MaterialContent',
                        component: () => import('@/views/construction/materialManagement/materialRequisition/content.vue'),
                        meta: {
                            title: '材料请购编辑',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/materialManagement/materialRequisition',
                            guard: ['MaterialContent'],
                        },
                    },
                    {
                        path: 'materialContentApproval',
                        name: 'MaterialContentApproval',
                        component: () => import('@/views/construction/materialManagement/materialRequisition/approval.vue'),
                        meta: {
                            title: '材料请购审批',
                            activeMenu: '/construction/materialManagement/materialRequisition',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['MaterialContentApproval'],
                        },
                    },
                    {
                        path: 'materialContentDetail',
                        name: 'MaterialContentDetail',
                        component: () => import('@/views/construction/materialManagement/materialRequisition/detail.vue'),
                        meta: {
                            title: '材料请购详情',
                            activeMenu: '/construction/materialManagement/materialRequisition',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['MaterialContentDetail'],
                        },
                    },
                    {
                        path: 'pickLists',
                        name: 'PickLists',
                        component: () => import('@/views/construction/materialManagement/pickLists/index.vue'),
                        meta: {
                            title: '材料领料',
                            noKeepAlive: true,
                            guard: ['PickLists'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'pickListContent',
                        name: 'PickListContent',
                        component: () => import('@/views/construction/materialManagement/pickLists/content.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/materialManagement/pickLists',
                            title: '材料领料编辑页',
                            guard: ['PickListContent'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'pickListDetail',
                        name: 'PickListDetail',
                        component: () => import('@/views/construction/materialManagement/pickLists/detail.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/materialManagement/pickLists',
                            title: '材料领料详情页',
                            noKeepAlive: true,
                            guard: ['PickListDetail'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'pickListApproval',
                        name: 'PickListApproval',
                        component: () => import('@/views/construction/materialManagement/pickLists/approval.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/materialManagement/pickLists',
                            title: '材料领料审批页',
                            noKeepAlive: true,
                            guard: ['PickListApproval'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'materialReturn',
                        name: 'MaterialReturn',
                        component: () => import('@/views/construction/materialManagement/materialReturn/index.vue'),
                        meta: {
                            title: '材料退料',
                            noKeepAlive: true,
                            guard: ['MaterialReturn'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'materialReturnContent',
                        name: 'MaterialReturnContent',
                        component: () => import('@/views/construction/materialManagement/materialReturn/content.vue'),
                        meta: {
                            title: '材料退料编辑',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/materialManagement/materialReturn',
                            guard: ['MaterialReturnContent'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'materialReturnApproval',
                        name: 'MaterialReturnApproval',
                        component: () => import('@/views/construction/materialManagement/materialReturn/approval.vue'),
                        meta: {
                            title: '材料退料审批',
                            activeMenu: '/construction/materialManagement/materialReturn',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['MaterialReturnApproval'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                    {
                        path: 'materialReturnDetail',
                        name: 'MaterialReturnDetail',
                        component: () => import('@/views/construction/materialManagement/materialReturn/detail.vue'),
                        meta: {
                            title: '材料退料详情',
                            activeMenu: '/construction/materialManagement/materialReturn',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['MaterialReturnDetail'],
                            dutiesId: EApprovalDuties.materialManagement,
                        },
                    },
                ],
            },
            {
                path: 'machineryManagement',
                name: 'MachineryManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '机械管理',
                    guard: ['MachineryManagement'],
                },
                children: [
                    {
                        path: 'equipmentManagement',
                        name: 'EquipmentManagement',
                        component: () => import('@/views/construction/machineryManagement/equipmentManagement/index.vue'),
                        meta: {
                            title: '设备租赁管理',
                            noKeepAlive: true,
                            guard: ['EquipmentManagement'],
                            dutiesId: EApprovalDuties.machineryManagement,
                        },
                    },
                    {
                        path: 'equipmentManagementContent',
                        name: 'EquipmentManagementContent',
                        component: () => import('@/views/construction/machineryManagement/equipmentManagement/content.vue'),
                        meta: {
                            title: '设备租赁管理详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/machineryManagement/equipmentManagement',
                            guard: ['EquipmentManagementContent'],
                            dutiesId: EApprovalDuties.machineryManagement,
                        },
                    },
                    {
                        path: 'equipmentProgress',
                        name: 'EquipmentProgress',
                        component: () => import('@/views/construction/machineryManagement/equipmentProgress/index.vue'),
                        meta: {
                            title: '设备租赁进度',
                            noKeepAlive: true,
                            guard: ['EquipmentProgress'],
                            dutiesId: EApprovalDuties.machineryManagement,
                        },
                    },
                    {
                        path: 'equipmentProgressContent',
                        name: 'EquipmentProgressContent',
                        component: () => import('@/views/construction/machineryManagement/equipmentProgress/content.vue'),
                        meta: {
                            title: '设备租赁进度详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/machineryManagement/equipmentProgress',
                            guard: ['EquipmentProgressContent'],
                        },
                    },
                    {
                        path: 'equipmentRectification',
                        name: 'EquipmentRectification',
                        component: () => import('@/views/construction/machineryManagement/equipmentRectification/index.vue'),
                        meta: {
                            title: '设备租赁整改',
                            noKeepAlive: true,
                            guard: ['EquipmentRectification'],
                            dutiesId: EApprovalDuties.machineryManagement,
                        },
                    },
                    {
                        path: 'equipmentRectificationContent',
                        name: 'EquipmentRectificationContent',
                        component: () => import('@/views/construction/machineryManagement/equipmentRectification/content.vue'),
                        meta: {
                            title: '设备租赁整改详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/machineryManagement/equipmentRectification',
                            guard: ['EquipmentRectificationContent'],
                        },
                    },
                ],
            },
            {
                path: 'laborManagement',
                name: 'LaborManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '劳务管理',
                    guard: ['LaborManagement'],
                },
                children: [
                    {
                        path: 'laborArchives',
                        name: 'LaborArchives',
                        component: () => import('@/views/construction/laborManagement/laborArchives/index.vue'),
                        meta: {
                            title: '劳务档案',
                            noKeepAlive: true,
                            guard: ['LaborArchives'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborArchivesContent',
                        name: 'LaborArchivesContent',
                        component: () => import('@/views/construction/laborManagement/laborArchives/content.vue'),
                        meta: {
                            title: '劳务档案详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/laborManagement/laborArchives',
                            guard: ['LaborArchivesContent'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'professionalSub',
                        name: 'ProfessionalSub',
                        component: () => import('@/views/construction/laborManagement/professionalSub/index.vue'),
                        meta: {
                            title: '专业分包',
                            noKeepAlive: true,
                            guard: ['ProfessionalSub'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'professionalSubContent',
                        name: 'ProfessionalSubContent',
                        component: () => import('@/views/construction/laborManagement/professionalSub/content.vue'),
                        meta: {
                            title: '专业分包详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/laborManagement/professionalSub',
                            guard: ['ProfessionalSubContent'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborSub',
                        name: 'LaborSub',
                        component: () => import('@/views/construction/laborManagement/laborSub/index.vue'),
                        meta: {
                            title: '劳务分包',
                            noKeepAlive: true,
                            guard: ['LaborSub'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborSubContent',
                        name: 'LaborSubContent',
                        component: () => import('@/views/construction/laborManagement/laborSub/content.vue'),
                        meta: {
                            title: '劳务分包详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/laborManagement/laborSub',
                            guard: ['LaborSubContent'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'LaborProgress',
                        name: 'LaborProgress',
                        component: () => import('@/views/construction/laborManagement/LaborProgress/index.vue'),
                        meta: {
                            title: '劳务进度管理',
                            noKeepAlive: true,
                            guard: ['LaborProgress'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborProgressContent',
                        name: 'LaborProgressContent',
                        component: () => import('@/views/construction/laborManagement/LaborProgress/content.vue'),
                        meta: {
                            title: '劳务进度管理详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/laborManagement/LaborProgress',
                            guard: ['LaborProgressContent'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborProcess',
                        name: 'LaborProcess',
                        component: () => import('@/views/construction/laborManagement/laborProcess/index.vue'),
                        meta: {
                            title: '劳务工艺整改',
                            noKeepAlive: true,
                            guard: ['LaborProcess'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                    {
                        path: 'laborProcessContent',
                        name: 'LaborProcessContent',
                        component: () => import('@/views/construction/laborManagement/laborProcess/content.vue'),
                        meta: {
                            title: '劳务工艺整改详情',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/construction/laborManagement/laborProcess',
                            guard: ['LaborProcessContent'],
                            dutiesId: EApprovalDuties.laborManagement,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/completion',
        name: 'Completion',
        component: Layout,
        meta: {
            title: '竣工管理',
            icon: 'rainbow-line',
            guard: ['Completion'],
        },
        children: [
            {
                path: 'completionData',
                name: 'CompletionData',
                component: () => import('@/views/completion/completionData/index.vue'),
                meta: {
                    title: '竣工资料',
                    noKeepAlive: true,
                    guard: ['CompletionData'],
                },
            },
            {
                path: 'compDataContent',
                name: 'CompDataContent',
                component: () => import('@/views/completion/completionData/compDataContent.vue'),
                meta: {
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/completion/completionData',
                    title: '竣工资料编辑页',
                    guard: ['CompDataContent'],
                },
            },
            {
                path: 'projectSettlement',
                name: 'ProjectSettlement',
                component: () => import('@/views/completion/projectSettlement/index.vue'),
                meta: {
                    title: '项目结算',
                    noKeepAlive: true,
                    guard: ['ProjectSettlement'],
                },
            },
            {
                path: 'settlementContent',
                name: 'SettlementContent',
                component: () => import('@/views/completion/projectSettlement/settlementContent.vue'),
                meta: {
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/completion/projectSettlement',
                    title: '项目结算编辑页',
                    guard: ['SettlementContent'],
                },
            },
        ],
    },
    {
        path: '/afterSales',
        name: 'AfterSales',
        component: Layout,
        meta: {
            title: '售后管理',
            icon: '24-hours-line',
            guard: ['AfterSales'],
        },
        children: [
            {
                path: 'projectFollowUp',
                name: 'ProjectFollowUp',
                component: () => import('@/views/afterSales/projectFollowUp/index.vue'),
                meta: {
                    title: '项目回访',
                    noKeepAlive: true,
                    guard: ['ProjectFollowUp'],
                },
            },
            {
                path: 'projectFollowUpContent',
                name: 'ProjectFollowUpContent',
                component: () => import('@/views/afterSales/projectFollowUp/content.vue'),
                meta: {
                    title: '项目回访编辑页',
                    activeMenu: '/afterSales/projectFollowUp',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProjectFollowUpContent'],
                },
            },
            {
                path: 'maintenanceRecord',
                name: 'MaintenanceRecord',
                component: () => import('@/views/afterSales/maintenanceRecord/index.vue'),
                meta: {
                    title: '项目维修记录',
                    noKeepAlive: true,
                    guard: ['MaintenanceRecord'],
                },
            },
            {
                path: 'maintenanceRecordContent',
                name: 'MaintenanceRecordContent',
                component: () => import('@/views/afterSales/maintenanceRecord/content.vue'),
                meta: {
                    title: '项目维修记录编辑页',
                    activeMenu: '/afterSales/maintenanceRecord',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['MaintenanceRecordContent'],
                },
            },
            // {
            //   path: 'warranty',
            //   name: 'Warranty',
            //   component: () => import('@/views/afterSales/warranty/index.vue'),
            //   meta: {
            //     title: '质保金台账',
            //     noKeepAlive: true,
            //     guard: ['Warranty'],
            //     dutiesId: EApprovalDuties.afterSalesManagement,
            //   },
            // },
            // {
            //   path: 'warrantyContent',
            //   name: 'WarrantyContent',
            //   component: () => import('@/views/afterSales/warranty/content.vue'),
            //   meta: {
            //     title: '质保金退回详情',
            //     activeMenu: '/afterSales/warranty',
            //     hidden: true,
            //     dynamicNewTab: true,
            //     guard: ['WarrantyContent'],
            //     dutiesId: EApprovalDuties.afterSalesManagement,
            //   },
            // },
        ],
    },
    {
        path: '/procure',
        name: 'Procure',
        component: Layout,
        meta: {
            title: '采购管理',
            icon: 'shopping-bag-2-line',
            guard: ['Procure'],
        },
        children: [
            {
                path: 'materialApply',
                name: 'MaterialApply',
                component: () => import('@/views/procure/materialApply/index.vue'),
                meta: {
                    title: '材料申购',
                    noKeepAlive: true,
                    guard: ['MaterialApply'],
                },
            },
            {
                path: 'materialApplyContent',
                name: 'MaterialApplyContent',
                component: () => import('@/views/procure/materialApply/content.vue'),
                meta: {
                    activeMenu: '/procure/materialApply',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料申购编辑页',
                    guard: ['MaterialApplyContent'],
                },
            },
            {
                path: 'materialApplyDetail',
                name: 'MaterialApplyDetail',
                component: () => import('@/views/procure/materialApply/detail.vue'),
                meta: {
                    activeMenu: '/procure/materialApply',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料申购详情页',
                    noKeepAlive: true,
                    guard: ['MaterialApplyDetail'],
                },
            },
            {
                path: 'materialApplyApproval',
                name: 'MaterialApplyApproval',
                component: () => import('@/views/procure/materialApply/approval.vue'),
                meta: {
                    activeMenu: '/procure/materialApply',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '材料申购审批页',
                    noKeepAlive: true,
                    guard: ['MaterialApplyApproval'],
                },
            },
            {
                path: 'quotationManagement',
                name: 'QuotationManagement',
                component: () => import('@/views/procure/quotationManagement/index.vue'),
                meta: {
                    title: '报价单管理',
                    noKeepAlive: true,
                    guard: ['QuotationManagement'],
                },
            },
            {
                path: 'quotationContent',
                name: 'QuotationContent',
                component: () => import('@/views/procure/quotationManagement/content.vue'),
                meta: {
                    activeMenu: '/procure/quotationManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '报价单管理编辑页',
                    guard: ['QuotationContent'],
                },
            },
            {
                path: 'quotationContentReadOnly',
                name: 'QuotationContentReadOnly',
                component: () => import('@/views/procure/quotationManagement/readOnly.vue'),
                meta: {
                    title: '报价单管理详情',
                    activeMenu: '/procure/quotationManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['QuotationContentReadOnly'],
                },
            },
            {
                path: 'purchaseOrderOpening',
                name: 'PurchaseOrderOpening',
                component: () => import('@/views/procure/purchaseOrderOpening/index.vue'),
                meta: {
                    title: '订购单管理',
                    noKeepAlive: true,
                    guard: ['PurchaseOrderOpening'],
                },
            },
            {
                path: 'purchaseOrderOpeningContent',
                name: 'PurchaseOrderOpeningContent',
                component: () => import('@/views/procure/purchaseOrderOpening/content.vue'),
                meta: {
                    title: '订购单管理编辑页',
                    activeMenu: '/procure/purchaseOrderOpening',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['PurchaseOrderOpeningContent'],
                },
            },
            {
                path: 'purchaseOrderOpeningContentReadOnly',
                name: 'PurchaseOrderOpeningContentReadOnly',
                component: () => import('@/views/procure/purchaseOrderOpening/readOnly.vue'),
                meta: {
                    title: '订购单管理详情',
                    activeMenu: '/procure/purchaseOrderOpening',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['PurchaseOrderOpeningContentReadOnly'],
                },
            },
            {
                path: 'procurementContract',
                name: 'ProcurementContract',
                component: () => import('@/views/procure/procurementContract/index.vue'),
                meta: {
                    title: '采购合同',
                    noKeepAlive: true,
                    guard: ['ProcurementContract'],
                },
            },
            {
                path: 'procurementContractContent',
                name: 'ProcurementContractContent',
                component: () => import('@/views/procure/procurementContract/content.vue'),
                meta: {
                    title: '采购合同编辑页',
                    activeMenu: '/procure/procurementContract',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProcurementContractContent'],
                },
            },
            // {
            //   path: 'materialAcceptance',
            //   name: 'MaterialAcceptance',
            //   component: () => import('@/views/procure/materialAcceptance/index.vue'),
            //   meta: {
            //     title: '材料验收',
            //     guard: ['MaterialAcceptance'],
            //   },
            // },
            // {
            //   path: 'materialAcceptanceContent',
            //   name: 'MaterialAcceptanceContent',
            //   component: () =>
            //     import('@/views/procure/materialAcceptance/content.vue'),
            //   meta: {
            //     title: '材料验收详情页',
            //     activeMenu: '/procure/materialAcceptance',
            //     hidden: true,
            //     dynamicNewTab: true,
            //     guard: ['MaterialAcceptanceContent'],
            //     dutiesId: EApprovalDuties.materialAcceptance,
            //   },
            // },
            {
                path: 'purchaseReturn',
                name: 'PurchaseReturn',
                component: () => import('@/views/procure/purchaseReturn/index.vue'),
                meta: {
                    title: '采购退货',
                    noKeepAlive: true,
                    guard: ['PurchaseReturn'],
                },
            },
            {
                path: 'purchaseReturnContent',
                name: 'PurchaseReturnContent',
                component: () => import('@/views/procure/purchaseReturn/content.vue'),
                meta: {
                    title: '采购退货编辑页',
                    activeMenu: '/procure/purchaseReturn',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['PurchaseReturnContent'],
                },
            },
            // {
            //   path: 'procurementSettlement',
            //   name: 'ProcurementSettlement',
            //   component: () => import('@/views/procure/manage/index.vue'),
            //   meta: {
            //     title: '采购结算',
            //     noKeepAlive: true,
            //     guard: ['ProcurementSettlement'],
            //   },
            // },
            // {
            //   path: 'procurementLedger',
            //   name: 'ProcurementLedger',
            //   component: () => import('@/views/procure/manage/index.vue'),
            //   meta: {
            //     title: '采购台账',
            //     noKeepAlive: true,
            //     guard: ['ProcurementLedger'],
            //   },
            // },
            {
                path: 'settlementManagement',
                name: 'SettlementManagement',
                component: () => import('@/views/procure/settlementManagement/index.vue'),
                meta: {
                    title: '结算管理',
                    noKeepAlive: true,
                    guard: ['SettlementManagement'],
                },
            },
            {
                path: 'settlementManagementContent',
                name: 'SettlementManagementContent',
                component: () => import('@/views/procure/settlementManagement/content.vue'),
                meta: {
                    title: '结算管理编辑页',
                    activeMenu: '/procure/settlementManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['SettlementManagementContent'],
                },
            },
            {
                path: 'settlementManagementDetail',
                name: 'SettlementManagementDetail',
                component: () => import('@/views/procure/settlementManagement/detail.vue'),
                meta: {
                    title: '结算管理详情页',
                    activeMenu: '/procure/settlementManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['SettlementManagementDetail'],
                },
            },
            {
                path: 'settlementManagementtApproval',
                name: 'SettlementManagementtApproval',
                component: () => import('@/views/procure/settlementManagement/approval.vue'),
                meta: {
                    title: '结算管理审批页',
                    activeMenu: '/procure/settlementManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['SettlementManagementtApproval'],
                },
            },
        ],
    },
    {
        path: '/goods',
        name: 'Goods',
        component: Layout,
        meta: {
            title: '物资管理',
            icon: 'briefcase-5-line',
            guard: ['Goods'],
        },
        children: [
            {
                path: 'proMaterialIssuance',
                name: 'ProMaterialIssuance',
                component: () => import('@/views/goods/proMaterialIssuance/index.vue'),
                meta: {
                    title: '项目发料',
                    guard: ['ProMaterialIssuance'],
                },
            },
            {
                path: 'proMaterialIssuanceContent',
                name: 'ProMaterialIssuanceContent',
                component: () => import('@/views/goods/proMaterialIssuance/content.vue'),
                meta: {
                    title: '项目发料编辑页',
                    activeMenu: '/goods/proMaterialIssuance',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProMaterialIssuanceContent'],
                },
            },
            {
                path: 'proMaterialIssuanceDetail',
                name: 'ProMaterialIssuanceDetail',
                component: () => import('@/views/goods/proMaterialIssuance/detail.vue'),
                meta: {
                    title: '项目发料详情',
                    activeMenu: '/goods/proMaterialIssuance',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProMaterialIssuanceDetail'],
                },
            },
            {
                path: 'proMaterialReturn',
                name: 'ProMaterialReturn',
                component: () => import('@/views/goods/proMaterialReturn/index.vue'),
                meta: {
                    title: '项目退料',
                    guard: ['ProMaterialReturn'],
                },
            },
            {
                path: 'proMaterialReturnContent',
                name: 'ProMaterialReturnContent',
                component: () => import('@/views/goods/proMaterialReturn/content.vue'),
                meta: {
                    title: '项目退料编辑页',
                    activeMenu: '/goods/proMaterialReturn',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProMaterialReturnContent'],
                },
            },
            {
                path: 'proMaterialReturnDetail',
                name: 'ProMaterialReturnDetail',
                component: () => import('@/views/goods/proMaterialReturn/detail.vue'),
                meta: {
                    title: '项目退料详情',
                    activeMenu: '/goods/proMaterialReturn',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ProMaterialReturnDetail'],
                },
            },
            {
                path: 'materialTransfer',
                name: 'MaterialTransfer',
                component: () => import('@/views/goods/materialTransfer/index.vue'),
                meta: {
                    title: '材料调拨',
                    noKeepAlive: true,
                    guard: ['MaterialTransfer'],
                },
            },
            {
                path: 'materialTransferContent',
                name: 'MaterialTransferContent',
                component: () => import('@/views/goods/materialTransfer/content.vue'),
                meta: {
                    title: '材料调拨编辑页',
                    activeMenu: '/goods/materialTransfer',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['MaterialTransferContent'],
                },
            },
            {
                path: 'materialCatalog',
                name: 'MaterialCatalog',
                component: () => import('@/views/goods/materialCatalog/index.vue'),
                meta: {
                    title: '材料编码列表',
                    guard: ['MaterialCatalog'],
                    // noKeepAlive: true,
                },
            },
            {
                path: 'materialAcceptance',
                name: 'MaterialAcceptance',
                component: () => import('@/views/goods/materialAcceptance/index.vue'),
                meta: {
                    title: '材料验收',
                    noKeepAlive: true,
                    guard: ['MaterialAcceptance'],
                },
            },
            {
                path: 'materialAcceptanceContent',
                name: 'MaterialAcceptanceContent',
                component: () => import('@/views/goods/materialAcceptance/content.vue'),
                meta: {
                    title: '材料验收详情',
                    activeMenu: '/goods/materialAcceptance',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['MaterialAcceptanceContent'],
                },
            },
            {
                path: 'materialRejection',
                name: 'MaterialRejection',
                component: () => import('@/views/goods/materialRejection/index.vue'),
                meta: {
                    title: '材料验退',
                    noKeepAlive: true,
                    guard: ['MaterialRejection'],
                },
            },
            {
                path: 'materialRejectionContent',
                name: 'MaterialRejectionContent',
                component: () => import('@/views/goods/materialRejection/content.vue'),
                meta: {
                    title: '材料验退详情',
                    hidden: true,
                    activeMenu: '/goods/materialRejection',
                    dynamicNewTab: true,
                    guard: ['MaterialRejectionContent'],
                },
            },
            {
                path: 'inventory',
                name: 'Inventory',
                component: () => import('@/views/goods/inventory/index.vue'),
                meta: {
                    title: '即时库存',
                    guard: ['Inventory'],
                    noKeepAlive: true,
                },
            },
            {
                path: 'inventoryDetails',
                name: 'InventoryDetails',
                component: () => import('@/views/goods/inventory/details.vue'),
                meta: {
                    title: '即时库存详情页',
                    guard: ['Inventory'],
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/goods/inventory',
                },
            },
            {
                path: 'storeSettings',
                name: 'StoreSettings',
                component: () => import('@/views/goods/storeSettings/index.vue'),
                meta: {
                    title: '仓库管理',
                    guard: ['StoreSettings'],
                    noKeepAlive: true,
                },
            },
            {
                path: 'inventoryCount',
                name: 'InventoryCount',
                component: () => import('@/views/goods/inventoryCount/index.vue'),
                meta: {
                    title: '库存盘点',
                    noKeepAlive: true,
                    guard: ['InventoryCount'],
                },
            },
            {
                path: 'inventoryCountContent',
                name: 'InventoryCountContent',
                component: () => import('@/views/goods/inventoryCount/content.vue'),
                meta: {
                    title: '库存盘点编辑页',
                    activeMenu: '/goods/inventoryCount',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['InventoryCountContent'],
                },
            },
            {
                path: 'inventoryCountDetail',
                name: 'InventoryCountDetail',
                component: () => import('@/views/goods/inventoryCount/detail.vue'),
                meta: {
                    title: '库存盘点详情页',
                    activeMenu: '/goods/inventoryCount',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['InventoryCountDetail'],
                },
            },
            {
                path: 'inventoryCountApproval',
                name: 'InventoryCountApproval',
                component: () => import('@/views/goods/inventoryCount/approval.vue'),
                meta: {
                    title: '库存盘点审批页',
                    activeMenu: '/goods/inventoryCount',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['InventoryCountApproval'],
                },
            },
            {
                path: 'generalHandleStorage',
                name: 'GeneralHandleStorage',
                component: () => import('@/views/goods/generalHandleStorage/index.vue'),
                meta: {
                    title: '通用出入库',
                    noKeepAlive: true,
                    guard: ['GeneralHandleStorage'],
                },
            },
            {
                path: 'generalHandleStorageContent',
                name: 'GeneralHandleStorageContent',
                component: () => import('@/views/goods/generalHandleStorage/content.vue'),
                meta: {
                    title: '通用出入库编辑页',
                    activeMenu: '/goods/generalHandleStorage',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['GeneralHandleStorageContent'],
                },
            },
            {
                path: 'generalHandleStorageDetail',
                name: 'GeneralHandleStorageDetail',
                component: () => import('@/views/goods/generalHandleStorage/detail.vue'),
                meta: {
                    title: '通用出入库详情页',
                    activeMenu: '/goods/generalHandleStorage',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['GeneralHandleStorageDetail'],
                },
            },
            {
                path: 'generalHandleStorageapproval',
                name: 'GeneralHandleStorageapproval',
                component: () => import('@/views/goods/generalHandleStorage/approval.vue'),
                meta: {
                    title: '通用出入库审批页',
                    activeMenu: '/goods/generalHandleStorage',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['GeneralHandleStorageapproval'],
                },
            },
            // {
            //   path: 'scrapRecycle',
            //   name: 'ScrapRecycle',
            //   component: () => import('@/views/goods/scrapRecycle/index.vue'),
            //   meta: {
            //     title: '废料回收处理',
            //     noKeepAlive: true,
            //     guard: ['ScrapRecycle'],
            //   },
            // },
            // {
            //   path: 'scrapRecycleContent',
            //   name: 'ScrapRecycleContent',
            //   component: () => import('@/views/goods/scrapRecycle/content.vue'),
            //   meta: {
            //     title: '废料回收处理详情',
            //     activeMenu: '/goods/scrapRecycle',
            //     hidden: true,
            //     dynamicNewTab: true,
            //     guard: ['ScrapRecycleContent'],
            //   },
            // },
            {
                path: 'materialAcceptance',
                name: 'MaterialAcceptance',
                component: () => import('@/views/goods/materialAcceptance/index.vue'),
                meta: {
                    title: '材料验收',
                    noKeepAlive: true,
                    guard: ['MaterialAcceptance'],
                },
            },
            {
                path: 'materialAcceptanceContent',
                name: 'MaterialAcceptanceContent',
                component: () => import('@/views/goods/materialAcceptance/content.vue'),
                meta: {
                    title: '材料验收详情',
                    activeMenu: '/goods/materialAcceptance',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['MaterialAcceptanceContent'],
                },
            },
            {
                path: 'wasteAllocation',
                name: 'WasteAllocation',
                component: () => import('@/views/goods/wasteAllocation/index.vue'),
                meta: {
                    title: '废料调拨',
                    noKeepAlive: true,
                    guard: ['WasteAllocation'],
                },
            },
            {
                path: 'wasteAllocationContent',
                name: 'WasteAllocationContent',
                component: () => import('@/views/goods/wasteAllocation/content.vue'),
                meta: {
                    title: '废料调拨编辑页',
                    activeMenu: '/goods/wasteAllocation',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteAllocationContent'],
                },
            },
            {
                path: 'wasteAllocationApproval',
                name: 'WasteAllocationApproval',
                component: () => import('@/views/goods/wasteAllocation/approval.vue'),
                meta: {
                    title: '废料调拨审批',
                    activeMenu: '/goods/wasteAllocation',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteAllocationApproval'],
                },
            },
            {
                path: 'wasteAllocationDetail',
                name: 'WasteAllocationDetail',
                component: () => import('@/views/goods/wasteAllocation/detail.vue'),
                meta: {
                    title: '废料调拨详情',
                    activeMenu: '/goods/wasteAllocation',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteAllocationDetail'],
                },
            },
            {
                path: 'wasteDisposal',
                name: 'WasteDisposal',
                component: () => import('@/views/goods/wasteDisposal/index.vue'),
                meta: {
                    title: '废料处理',
                    noKeepAlive: true,
                    guard: ['WasteDisposal'],
                },
            },
            {
                path: 'wasteDisposalContent',
                name: 'WasteDisposalContent',
                component: () => import('@/views/goods/wasteDisposal/content.vue'),
                meta: {
                    title: '废料处理编辑',
                    activeMenu: '/goods/wasteDisposal',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteDisposalContent'],
                },
            },
            {
                path: 'wasteDisposalApproval',
                name: 'WasteDisposalApproval',
                component: () => import('@/views/goods/wasteDisposal/approval.vue'),
                meta: {
                    title: '废料处理审批',
                    activeMenu: '/goods/wasteDisposal',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteDisposalApproval'],
                },
            },
            {
                path: 'wasteDisposalDetail',
                name: 'WasteDisposalDetail',
                component: () => import('@/views/goods/wasteDisposal/detail.vue'),
                meta: {
                    title: '废料处理详情',
                    activeMenu: '/goods/wasteDisposal',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['WasteDisposalDetail'],
                },
            },
        ],
    },
    {
        path: '/finance',
        name: 'Finance',
        component: Layout,
        meta: {
            title: '财务管理',
            icon: 'draft-line',
            guard: ['Finance'],
        },
        children: [
            {
                path: 'reimbursement',
                name: 'Reimbursement',
                component: () => import('@/views/finance/reimbursement/index.vue'),
                meta: {
                    title: '费用报销',
                    noKeepAlive: true,
                    guard: ['Reimbursement'],
                },
            },
            {
                path: 'reimbursementContent',
                name: 'ReimbursementContent',
                component: () => import('@/views/finance/reimbursement/content.vue'),
                meta: {
                    title: '费用报销详情',
                    activeMenu: '/finance/reimbursement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ReimbursementContent'],
                },
            },
            {
                path: 'debitNote',
                name: 'DebitNote',
                component: () => import('@/views/finance/debitNote/index.vue'),
                meta: {
                    title: '借支单',
                    noKeepAlive: true,
                    guard: ['DebitNote'],
                },
            },
            {
                path: 'debitNoteContent',
                name: 'DebitNoteContent',
                component: () => import('@/views/finance/debitNote/content.vue'),
                meta: {
                    title: '借支单编辑页',
                    activeMenu: '/finance/debitNote',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['DebitNoteContent'],
                },
            },
            {
                path: 'payWarrant',
                name: 'PayWarrant',
                component: () => import('@/views/finance/payWarrant/index.vue'),
                meta: {
                    title: '支付证明单',
                    noKeepAlive: true,
                    guard: ['PayWarrant'],
                },
            },
            {
                path: 'payWarrantContent',
                name: 'PayWarrantContent',
                component: () => import('@/views/finance/payWarrant/content.vue'),
                meta: {
                    title: '支付证明单编辑页',
                    activeMenu: '/finance/payWarrant',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['PayWarrantContent'],
                },
            },
            {
                path: 'receipt',
                name: 'Receipt',
                component: () => import('@/views/finance/receipt/index.vue'),
                meta: {
                    title: '收款管理',
                    noKeepAlive: true,
                    guard: ['Receipt'],
                },
            },
            {
                path: 'receiptContent',
                name: 'ReceiptContent',
                component: () => import('@/views/finance/receipt/content.vue'),
                meta: {
                    activeMenu: '/finance/receipt',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '收款管理编辑页',
                    guard: ['ReceiptContent'],
                },
            },
            {
                path: 'receiptDetail',
                name: 'ReceiptDetail',
                component: () => import('@/views/finance/receipt/detail.vue'),
                meta: {
                    activeMenu: '/finance/receipt',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '收款管理详情页',
                    guard: ['ReceiptDetail'],
                },
            },
            {
                path: 'receiptApproval',
                name: 'ReceiptApproval',
                component: () => import('@/views/finance/receipt/approval.vue'),
                meta: {
                    activeMenu: '/finance/receipt',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '收款管理审批页',
                    guard: ['ReceiptApproval'],
                },
            },
            {
                path: 'marginManagement',
                name: 'MarginManagement',
                meta: {
                    title: '保证金管理',
                    guard: ['MarginManagement'],
                },
                children: [
                    {
                        path: 'paymentFromPartyA',
                        name: 'PaymentFromPartyA',
                        component: () => import('@/views/finance/marginManagement/paymentFromPartyA/index.vue'),
                        meta: {
                            title: '对甲支付保证金',
                            noKeepAlive: true,
                            guard: ['PaymentFromPartyA'],
                        },
                    },
                    {
                        path: 'partyADepositReceived',
                        name: 'PartyADepositReceived',
                        component: () => import('@/views/finance/marginManagement/partyADepositReceived/index.vue'),
                        meta: {
                            title: '对甲收回保证金',
                            noKeepAlive: true,
                            guard: ['PartyADepositReceived'],
                        },
                    },
                    {
                        path: 'partyBDepositReceived',
                        name: 'PartyBDepositReceived',
                        component: () => import('@/views/finance/marginManagement/partyBDepositReceived/index.vue'),
                        meta: {
                            title: '对乙收取保证金',
                            noKeepAlive: true,
                            guard: ['PartyBDepositReceived'],
                        },
                    },
                    {
                        path: 'refundToPartyB',
                        name: 'RefundToPartyB',
                        component: () => import('@/views/finance/marginManagement/refundToPartyB/index.vue'),
                        meta: {
                            title: '对乙退回保证金',
                            noKeepAlive: true,
                            guard: ['RefundToPartyB'],
                        },
                    },
                ],
            },
            {
                path: 'warrantyManagement',
                name: 'WarrantyManagement',
                meta: {
                    title: '质保金管理',
                    guard: ['WarrantyManagement'],
                },
                children: [
                    {
                        path: 'partyAPayWarrantyDeposit',
                        name: 'PartyAPayWarrantyDeposit',
                        component: () => import('@/views/finance/warrantyManagement/partyAPayWarrantyDeposit/index.vue'),
                        meta: {
                            title: '对甲支付质保金',
                            noKeepAlive: true,
                            guard: ['PartyAPayWarrantyDeposit'],
                        },
                    },
                    {
                        path: 'partyAReclaimWarrantyDeposit',
                        name: 'PartyAReclaimWarrantyDeposit',
                        component: () => import('@/views/finance/warrantyManagement/partyAReclaimWarrantyDeposit/index.vue'),
                        meta: {
                            title: '对甲收回质保金',
                            noKeepAlive: true,
                            guard: ['PartyAReclaimWarrantyDeposit'],
                        },
                    },
                    {
                        path: 'partyBCollectWarrantyDeposit',
                        name: 'PartyBCollectWarrantyDeposit',
                        component: () => import('@/views/finance/warrantyManagement/partyBCollectWarrantyDeposit/index.vue'),
                        meta: {
                            title: '对乙收取质保金',
                            noKeepAlive: true,
                            guard: ['PartyADepositReceived'],
                        },
                    },
                    {
                        path: 'partyBPayWarrantyDeposit',
                        name: 'PartyBPayWarrantyDeposit',
                        component: () => import('@/views/finance/warrantyManagement/partyBPayWarrantyDeposit/index.vue'),
                        meta: {
                            title: '对乙支付质保金',
                            noKeepAlive: true,
                            guard: ['PartyBPayWarrantyDeposit'],
                        },
                    },
                ],
            },
            {
                path: 'paymentManagement',
                name: 'PaymentManagement',
                meta: {
                    title: '付款管理',
                    guard: ['PaymentManagement'],
                },
                children: [
                    {
                        path: 'certificate',
                        name: 'Certificate',
                        component: () => import('@/views/finance/paymentManagement/certificate/index.vue'),
                        meta: {
                            title: '支付证明单',
                            noKeepAlive: true,
                            guard: ['Certificate'],
                        },
                    },
                    {
                        path: 'certificateContent',
                        name: 'CertificateContent',
                        component: () => import('@/views/finance/paymentManagement/certificate/content.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/certificate',
                            hidden: true,
                            dynamicNewTab: true,
                            title: '支付证明单编辑页',
                            guard: ['CertificateContent'],
                        },
                    },
                    {
                        path: 'certificateDetail',
                        name: 'CertificateDetail',
                        component: () => import('@/views/finance/paymentManagement/certificate/detail.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/certificate',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '支付证明单详情页',
                            guard: ['CertificateDetail'],
                        },
                    },
                    {
                        path: 'certificateApproval',
                        name: 'CertificateApproval',
                        component: () => import('@/views/finance/paymentManagement/certificate/approval.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/certificate',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '支付证明单审批页',
                            guard: ['CertificateApproval'],
                        },
                    },
                    {
                        path: 'payMoney',
                        name: 'PayMoney',
                        component: () => import('@/views/finance/paymentManagement/payMoney/index.vue'),
                        meta: {
                            title: '款项支付',
                            noKeepAlive: true,
                            guard: ['PayMoney'],
                        },
                    },
                    {
                        path: 'payMoneyContent',
                        name: 'PayMoneyContent',
                        component: () => import('@/views/finance/paymentManagement/payMoney/content.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/payMoney',
                            hidden: true,
                            dynamicNewTab: true,
                            title: '款项支付编辑页',
                            guard: ['PayMoneyContent'],
                        },
                    },
                    {
                        path: 'payMoneyDetail',
                        name: 'PayMoneyDetail',
                        component: () => import('@/views/finance/paymentManagement/payMoney/detail.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/payMoney',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '款项支付详情页',
                            guard: ['PayMoneyDetail'],
                        },
                    },
                    {
                        path: 'payMoneyApproval',
                        name: 'PayMoneyApproval',
                        component: () => import('@/views/finance/paymentManagement/payMoney/approval.vue'),
                        meta: {
                            activeMenu: '/finance/paymentManagement/payMoney',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '款项支付审批页',
                            guard: ['PayMoneyApproval'],
                        },
                    },
                ],
            },
            // {
            //   path: 'financeManage2',
            //   name: 'financeManage2',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '费用报销明细表',
            //     guard: ['financeManage2'],
            //   },
            // },
            // {
            //   path: 'financeManage3',
            //   name: 'financeManage3',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '借款申请',
            //     guard: ['financeManage3'],
            //   },
            // },
            // {
            //   path: 'financeManage4',
            //   name: 'financeManage4',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '借款申请表',
            //     guard: ['financeManage4'],
            //   },
            // },
            // {
            //   path: 'financeManage5',
            //   name: 'financeManage5',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '借支还款',
            //     guard: ['financeManage5'],
            //   },
            // },
            // {
            //   path: 'financeManage6',
            //   name: 'financeManage6',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '借支还款表',
            //     guard: ['financeManage6'],
            //   },
            // },
            // {
            //   path: 'financeManage7',
            //   name: 'financeManage7',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '劳务付款',
            //     guard: ['financeManage7'],
            //   },
            // },
            // {
            //   path: 'financeManage8',
            //   name: 'financeManage8',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '劳务付款表',
            //     guard: ['financeManage8'],
            //   },
            // },
            // {
            //   path: 'financeManage9',
            //   name: 'financeManage9',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '分包付款',
            //     guard: ['financeManage9'],
            //   },
            // },
            // {
            //   path: 'financeManage10',
            //   name: 'financeManage10',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '分包付款表',
            //     guard: ['financeManage10'],
            //   },
            // },
            // {
            //   path: 'financeManage11',
            //   name: 'financeManage11',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '租赁付款',
            //     guard: ['financeManage11'],
            //   },
            // },
            // {
            //   path: 'financeManage12',
            //   name: 'financeManage12',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '租赁付款表',
            //     guard: ['financeManage12'],
            //   },
            // },
            // {
            //   path: 'financeManage13',
            //   name: 'financeManage13',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '采购付款',
            //     guard: ['financeManage13'],
            //   },
            // },
            // {
            //   path: 'financeManage14',
            //   name: 'financeManage14',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '采购付款表',
            //     guard: ['financeManage14'],
            //   },
            // },
            // {
            //   path: 'financeManage15',
            //   name: 'financeManage15',
            //   component: () => import('@/views/finance/manage/index.vue'),
            //   meta: {
            //     title: '应付款汇总表',
            //     guard: ['financeManage15'],
            //   },
            // },
        ],
    },
    {
        path: '/personnel',
        name: 'Personnel',
        component: Layout,
        meta: {
            title: '人事管理',
            icon: 'group-line',
            guard: ['Personnel'],
        },
        children: [
            {
                path: 'roster',
                name: 'Roster',
                component: () => import('@/views/personnel/roster/index.vue'),
                meta: {
                    title: '员工花名册',
                    noKeepAlive: true,
                    guard: ['Roster'],
                },
            },
            {
                path: 'rosterContent',
                name: 'RosterContent',
                component: () => import('@/views/personnel/roster/content.vue'),
                meta: {
                    title: '员工花名册编辑页',
                    activeMenu: '/personnel/roster',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RosterContent'],
                },
            },
            {
                path: 'rosterDetail',
                name: 'RosterDetail',
                component: () => import('@/views/personnel/roster/detail.vue'),
                meta: {
                    title: '员工花名册详情页',
                    activeMenu: '/personnel/roster',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['RosterDetail'],
                },
            },
            {
                path: 'rosterApproval',
                name: 'RosterApproval',
                component: () => import('@/views/personnel/roster/approval.vue'),
                meta: {
                    title: '员工花名册审批页',
                    activeMenu: '/personnel/roster',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['RosterApproval'],
                },
            },
            {
                path: 'dimission',
                name: 'Dimission',
                component: () => import('@/views/personnel/dimission/index.vue'),
                meta: {
                    title: '离职管理',
                    noKeepAlive: true,
                    guard: ['Dimission'],
                },
            },
            {
                path: 'dimissionContent',
                name: 'DimissionContent',
                component: () => import('@/views/personnel/dimission/content.vue'),
                meta: {
                    activeMenu: '/personnel/dimission',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '离职管理编辑页',
                    guard: ['DimissionContent'],
                },
            },
            {
                path: 'dimissionDetail',
                name: 'DimissionDetail',
                component: () => import('@/views/personnel/dimission/detail.vue'),
                meta: {
                    activeMenu: '/personnel/dimission',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '离职管理详情页',
                    noKeepAlive: true,
                    guard: ['DimissionDetail'],
                },
            },
            {
                path: 'dimissionApproval',
                name: 'DimissionApproval',
                component: () => import('@/views/personnel/dimission/approval.vue'),
                meta: {
                    activeMenu: '/personnel/dimission',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '离职管理审批页',
                    noKeepAlive: true,
                    guard: ['DimissionApproval'],
                },
            },
            {
                path: 'personnelChange',
                name: 'PersonnelChange',
                component: () => import('@/views/personnel/personnelChange/index.vue'),
                meta: {
                    title: '人事调动',
                    noKeepAlive: true,
                    guard: ['PersonnelChange'],
                },
            },
            {
                path: 'personnelChangeContent',
                name: 'PersonnelChangeContent',
                component: () => import('@/views/personnel/personnelChange/content.vue'),
                meta: {
                    activeMenu: '/personnel/personnelChange',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '人事调动编辑页',
                    guard: ['PersonnelChangeContent'],
                },
            },
            {
                path: 'personnelChangeDetail',
                name: 'PersonnelChangeDetail',
                component: () => import('@/views/personnel/personnelChange/detail.vue'),
                meta: {
                    activeMenu: '/personnel/personnelChange',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '人事调动详情页',
                    noKeepAlive: true,
                    guard: ['PersonnelChangeDetail'],
                },
            },
            {
                path: 'personnelChangeApproval',
                name: 'PersonnelChangeApproval',
                component: () => import('@/views/personnel/personnelChange/approval.vue'),
                meta: {
                    activeMenu: '/personnel/personnelChange',
                    hidden: true,
                    dynamicNewTab: true,
                    title: '人事调动审批页',
                    noKeepAlive: true,
                    guard: ['PersonnelChangeApproval'],
                },
            },
            // {
            //   path: 'raise',
            //   name: 'Raise',
            //   component: () => import('@/views/personnel/raise/index.vue'),
            //   meta: {
            //     title: '员工调职调薪录入',
            //     guard: ['Raise'],
            //   },
            // },
            // {
            //   path: 'raiselist',
            //   name: 'Raiselist',
            //   component: () => import('@/views/personnel/approvelist/index.vue'),
            //   meta: {
            //     title: '员工调职调薪表',
            //     guard: ['Raiselist'],
            //   },
            // },
            // {
            //   path: 'resources',
            //   name: 'Resources',
            //   component: () => import('@/views/personnel/resources/index.vue'),
            //   meta: {
            //     title: '部门人力需求申请',
            //     guard: ['Resources'],
            //   },
            // },
            // {
            //   path: 'resourceslist',
            //   name: 'Resourceslist',
            //   component: () => import('@/views/personnel/resourceslist/index.vue'),
            //   meta: {
            //     title: '部门人力需求申请表',
            //     guard: ['Resourceslist'],
            //   },
            // },
            {
                path: 'rewards',
                name: 'Rewards',
                component: () => import('@/views/personnel/rewards/index.vue'),
                meta: {
                    title: '奖惩管理',
                    noKeepAlive: true,
                    guard: ['Rewards'],
                },
            },
            {
                path: 'rewardsContent',
                name: 'RewardsContent',
                component: () => import('@/views/personnel/rewards/content.vue'),
                meta: {
                    title: '奖惩管理编辑',
                    activeMenu: '/personnel/rewards',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RewardsContent'],
                },
            },
            {
                path: 'rewardsApproval',
                name: 'RewardsApproval',
                component: () => import('@/views/personnel/rewards/approval.vue'),
                meta: {
                    title: '奖惩管理审批',
                    activeMenu: '/personnel/rewards',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RewardsApproval'],
                },
            },
            {
                path: 'rewardsDetail',
                name: 'RewardsDetail',
                component: () => import('@/views/personnel/rewards/detail.vue'),
                meta: {
                    title: '奖惩管理详情',
                    activeMenu: '/personnel/rewards',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RewardsDetail'],
                },
            },
            {
                path: 'documentManagement',
                name: 'DocumentManagement',
                // component: () => import('@/views/completion/manage/index.vue'),
                meta: {
                    title: '证件管理',
                    guard: ['DocumentManagement'],
                },
                children: [
                    {
                        path: 'certificateLedger',
                        name: 'CertificateLedger',
                        component: () => import('@/views/personnel/documentManagement/certificateLedger/index.vue'),
                        meta: {
                            title: '证件台账',
                            noKeepAlive: true,
                            guard: ['CertificateLedger'],
                        },
                    },
                    {
                        path: 'certificateLedgerContent',
                        name: 'CertificateLedgerContent',
                        component: () => import('@/views/personnel/documentManagement/certificateLedger/content.vue'),
                        meta: {
                            title: '证件台账编辑',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/personnel/documentManagement/certificateLedger',
                            guard: ['CertificateLedgerContent'],
                        },
                    },
                    {
                        path: 'certificateLedgerDetail',
                        name: 'CertificateLedgerDetail',
                        component: () => import('@/views/personnel/documentManagement/certificateLedger/detail.vue'),
                        meta: {
                            title: '证件台账详情',
                            activeMenu: '/personnel/documentManagement/certificateLedger',
                            hidden: true,
                            dynamicNewTab: true,
                            guard: ['CertificateLedgerDetail'],
                        },
                    },
                    {
                        path: 'documentDorrowingAndReturning',
                        name: 'DocumentDorrowingAndReturning',
                        component: () => import('@/views/personnel/documentManagement/documentDorrowingAndReturning/index.vue'),
                        meta: {
                            title: '证件借还',
                            noKeepAlive: true,
                            guard: ['DocumentDorrowingAndReturning'],
                        },
                    },
                    {
                        path: 'documentDorrowingAndReturningContent',
                        name: 'DocumentDorrowingAndReturningContent',
                        component: () => import('@/views/personnel/documentManagement/documentDorrowingAndReturning/content.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/personnel/documentManagement/documentDorrowingAndReturning',
                            title: '证件借还编辑页',
                            guard: ['DocumentDorrowingAndReturningContent'],
                        },
                    },
                    {
                        path: 'documentDorrowingAndReturningDetail',
                        name: 'DocumentDorrowingAndReturningDetail',
                        component: () => import('@/views/personnel/documentManagement/documentDorrowingAndReturning/detail.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/personnel/documentManagement/documentDorrowingAndReturning',
                            title: '证件借还详情',
                            noKeepAlive: true,
                            guard: ['DocumentDorrowingAndReturningDetail'],
                        },
                    },
                    {
                        path: 'documentDorrowingAndReturningApproval',
                        name: 'DocumentDorrowingAndReturningApproval',
                        component: () => import('@/views/personnel/documentManagement/documentDorrowingAndReturning/approval.vue'),
                        meta: {
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/personnel/documentManagement/documentDorrowingAndReturning',
                            title: '证件借还审批页',
                            noKeepAlive: true,
                            guard: ['DocumentDorrowingAndReturningApproval'],
                        },
                    },
                ],
            },
            {
                path: 'contractManagement',
                name: 'ContractManagement',
                component: () => import('@/views/personnel/contractManagement/index.vue'),
                meta: {
                    title: '合同管理',
                    noKeepAlive: true,
                    guard: ['ContractManagement'],
                },
            },
            {
                path: 'contractManagementContent',
                name: 'ContractManagementContent',
                component: () => import('@/views/personnel/contractManagement/content.vue'),
                meta: {
                    title: '合同管理编辑页',
                    activeMenu: '/personnel/contractManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ContractManagement'],
                },
            },
            {
                path: 'contractManagementApproval',
                name: 'ContractManagementApproval',
                component: () => import('@/views/personnel/contractManagement/approval.vue'),
                meta: {
                    title: '合同管理审批页',
                    activeMenu: '/personnel/contractManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ContractManagementApproval'],
                },
            },
            {
                path: 'contractManagementDetail',
                name: 'ContractManagementDetail',
                component: () => import('@/views/personnel/contractManagement/detail.vue'),
                meta: {
                    title: '合同管理详情页',
                    activeMenu: '/personnel/contractManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ContractManagementDetail'],
                },
            },
            {
                path: 'regularManagement',
                name: 'RegularManagement',
                component: () => import('@/views/personnel/regularManagement/index.vue'),
                meta: {
                    title: '转正管理',
                    noKeepAlive: true,
                    guard: ['RegularManagement'],
                },
            },
            {
                path: 'regularManagementContent',
                name: 'RegularManagementContent',
                component: () => import('@/views/personnel/regularManagement/content.vue'),
                meta: {
                    title: '转正管理编辑页',
                    activeMenu: '/personnel/regularManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RegularManagement'],
                },
            },
            {
                path: 'regularManagementApproval',
                name: 'RegularManagementApproval',
                component: () => import('@/views/personnel/regularManagement/approval.vue'),
                meta: {
                    title: '转正管理审批',
                    activeMenu: '/personnel/regularManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RegularManagementApproval'],
                },
            },
            {
                path: 'regularManagementDetail',
                name: 'RegularManagementDetail',
                component: () => import('@/views/personnel/regularManagement/detail.vue'),
                meta: {
                    title: '转正管理详情',
                    activeMenu: '/personnel/regularManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['RegularManagementDetail'],
                },
            },
        ],
    },
    {
        path: '/adminiStration',
        name: 'AdminiStration',
        component: Layout,
        meta: {
            title: '行政管理',
            icon: 'settings-5-line',
            guard: ['AdminiStration'],
        },
        children: [
            {
                path: 'newsAlert',
                name: 'NewsAlert',
                component: () => import('@/views/adminiStration/newsAlert/index.vue'),
                meta: {
                    title: '新闻发布',
                    noKeepAlive: true,
                    guard: ['NewsAlert'],
                },
            },
            {
                path: 'newsAlertContent',
                name: 'NewsAlertContent',
                component: () => import('@/views/adminiStration/newsAlert/content.vue'),
                meta: {
                    title: '新闻发布编辑',
                    activeMenu: '/adminiStration/newsAlert',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['NewsAlertContent'],
                },
            },
            {
                path: 'knowledgManagement',
                name: 'KnowledgManagement',
                component: () => import('@/views/adminiStration/knowledgManagement/index.vue'),
                meta: {
                    title: '知识管理',
                    noKeepAlive: true,
                    guard: ['KnowledgManagement'],
                },
            },
            {
                path: 'knowledgManagementContent',
                name: 'KnowledgManagementContent',
                component: () => import('@/views/adminiStration/knowledgManagement/content.vue'),
                meta: {
                    title: '知识管理编辑',
                    activeMenu: '/adminiStration/knowledgManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['KnowledgManagementContent'],
                },
            },
            {
                path: 'corporateCulture',
                name: 'CorporateCulture',
                component: () => import('@/views/adminiStration/corporateCulture/index.vue'),
                meta: {
                    title: '企业文化',
                    noKeepAlive: true,
                    guard: ['CorporateCulture'],
                },
            },
            {
                path: 'corporateCultureContent',
                name: 'CorporateCultureContent',
                component: () => import('@/views/adminiStration/corporateCulture/content.vue'),
                meta: {
                    title: '企业文化编辑',
                    activeMenu: '/adminiStration/corporateCulture',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['CorporateCultureContent'],
                },
            },
            {
                path: 'conferenceManagement',
                name: 'ConferenceManagement',
                component: () => import('@/views/adminiStration/conferenceManagement/index.vue'),
                meta: {
                    title: '会议管理',
                    noKeepAlive: true,
                    guard: ['ConferenceManagement'],
                },
            },
            {
                path: 'conferenceManagementContent',
                name: 'ConferenceManagementContent',
                component: () => import('@/views/adminiStration/conferenceManagement/content.vue'),
                meta: {
                    title: '会议管理编辑',
                    activeMenu: '/adminiStration/conferenceManagement',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ConferenceManagementContent'],
                },
            },
            {
                path: 'officialDocument',
                name: 'OfficialDocument',
                component: () => import('@/views/adminiStration/officialDocument/index.vue'),
                meta: {
                    title: '公文管理',
                    noKeepAlive: true,
                    guard: ['DocumentManagement'],
                },
            },
            {
                path: 'officialDocumentContent',
                name: 'OfficialDocumentContent',
                component: () => import('@/views/adminiStration/officialDocument/content.vue'),
                meta: {
                    title: '公文管理编辑',
                    activeMenu: '/adminiStration/officialDocument',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['OfficialDocumentContent'],
                },
            },
            {
                path: 'vehicleApplication',
                name: 'VehicleApplication',
                component: () => import('@/views/adminiStration/vehicleApplication/index.vue'),
                meta: {
                    title: '用车申请',
                    noKeepAlive: true,
                    guard: ['VehicleApplication'],
                },
            },
            {
                path: 'vehicleApplicationContent',
                name: 'VehicleApplicationContent',
                component: () => import('@/views/adminiStration/vehicleApplication/content.vue'),
                meta: {
                    title: '用车申请编辑',
                    activeMenu: '/adminiStration/vehicleApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['VehicleApplicationContent'],
                },
            },
            {
                path: 'vehicleApplicationApproavl',
                name: 'VehicleApplicationApproavl',
                component: () => import('@/views/adminiStration/vehicleApplication/approval.vue'),
                meta: {
                    title: '用车申请审批',
                    activeMenu: '/adminiStration/vehicleApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['VehicleApplicationApproavl'],
                },
            },
            {
                path: 'vehicleApplicationDetail',
                name: 'VehicleApplicationDetail',
                component: () => import('@/views/adminiStration/vehicleApplication/detail.vue'),
                meta: {
                    title: '用车申请详情',
                    activeMenu: '/adminiStration/vehicleApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['VehicleApplicationDetail'],
                },
            },
            {
                path: 'leaveApplication',
                name: 'LeaveApplication',
                component: () => import('@/views/adminiStration/leaveApplication/index.vue'),
                meta: {
                    title: '请假申请',
                    noKeepAlive: true,
                    guard: ['LeaveApplication'],
                },
            },
            {
                path: 'leaveApplicationContent',
                name: 'LeaveApplicationContent',
                component: () => import('@/views/adminiStration/leaveApplication/content.vue'),
                meta: {
                    title: '请假申请编辑',
                    activeMenu: '/adminiStration/leaveApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['LeaveApplicationContent'],
                },
            },
            {
                path: 'leaveApplicationApproavl',
                name: 'LeaveApplicationApproavl',
                component: () => import('@/views/adminiStration/leaveApplication/approval.vue'),
                meta: {
                    title: '请假申请审批',
                    activeMenu: '/adminiStration/leaveApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['LeaveApplicationApproavl'],
                },
            },
            {
                path: 'leaveApplicationDetail',
                name: 'LeaveApplicationDetail',
                component: () => import('@/views/adminiStration/leaveApplication/detail.vue'),
                meta: {
                    title: '请假申请详情',
                    activeMenu: '/adminiStration/leaveApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['LeaveApplicationDetail'],
                },
            },
            {
                path: 'officialApplication',
                name: 'OfficialApplication',
                component: () => import('@/views/adminiStration/officialApplication/index.vue'),
                meta: {
                    title: '公章申请',
                    noKeepAlive: true,
                    guard: ['OfficialApplication'],
                },
            },
            {
                path: 'officialApplicationContent',
                name: 'OfficialApplicationContent',
                component: () => import('@/views/adminiStration/officialApplication/content.vue'),
                meta: {
                    title: '公章申请编辑',
                    activeMenu: '/adminiStration/officialApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['OfficialApplicationContent'],
                },
            },
            {
                path: 'officialApplicationApproavl',
                name: 'OfficialApplicationApproavl',
                component: () => import('@/views/adminiStration/officialApplication/approval.vue'),
                meta: {
                    title: '公章申请审批',
                    activeMenu: '/adminiStration/officialApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['OfficialApplicationApproavl'],
                },
            },
            {
                path: 'officialApplicationDetail',
                name: 'OfficialApplicationDetail',
                component: () => import('@/views/adminiStration/officialApplication/detail.vue'),
                meta: {
                    title: '公章申请详情',
                    activeMenu: '/adminiStration/officialApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['OfficialApplicationDetail'],
                },
            },
            {
                path: 'borrowingApplication',
                name: 'BorrowingApplication',
                component: () => import('@/views/adminiStration/borrowingApplication/index.vue'),
                meta: {
                    title: '借支申请',
                    noKeepAlive: true,
                    guard: ['BorrowingApplication'],
                },
            },
            {
                path: 'borrowingApplicationContent',
                name: 'BorrowingApplicationContent',
                component: () => import('@/views/adminiStration/borrowingApplication/content.vue'),
                meta: {
                    title: '借支申请编辑',
                    activeMenu: '/adminiStration/borrowingApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BorrowingApplicationContent'],
                },
            },
            {
                path: 'borrowingApplicationApproavl',
                name: 'BorrowingApplicationApproavl',
                component: () => import('@/views/adminiStration/borrowingApplication/approval.vue'),
                meta: {
                    title: '借支申请审批',
                    activeMenu: '/adminiStration/borrowingApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BorrowingApplicationApproavl'],
                },
            },
            {
                path: 'borrowingApplicationDetail',
                name: 'BorrowingApplicationDetail',
                component: () => import('@/views/adminiStration/borrowingApplication/detail.vue'),
                meta: {
                    title: '借支申请详情',
                    activeMenu: '/adminiStration/borrowingApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BorrowingApplicationDetail'],
                },
            },
            {
                path: 'paymentApplication',
                name: 'PaymentApplication',
                component: () => import('@/views/adminiStration/paymentApplication/index.vue'),
                meta: {
                    title: '支付申请',
                    noKeepAlive: true,
                    guard: ['PaymentApplication'],
                },
            },
            {
                path: 'paymentApplicationContent',
                name: 'PaymentApplicationContent',
                component: () => import('@/views/adminiStration/paymentApplication/content.vue'),
                meta: {
                    title: '支付申请编辑页',
                    activeMenu: '/adminiStration/paymentApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['PaymentApplicationContent'],
                },
            },
            {
                path: 'paymentApplicationApproavl',
                name: 'PaymentApplicationApproavl',
                component: () => import('@/views/adminiStration/paymentApplication/approval.vue'),
                meta: {
                    title: '支付申请审批页',
                    activeMenu: '/adminiStration/paymentApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['PaymentApplicationApproavl'],
                },
            },
            {
                path: 'paymentApplicationDetail',
                name: 'PaymentApplicationDetail',
                component: () => import('@/views/adminiStration/paymentApplication/detail.vue'),
                meta: {
                    title: '支付申请详情页',
                    activeMenu: '/adminiStration/paymentApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: true,
                    guard: ['PaymentApplicationDetail'],
                },
            },
            {
                path: 'reimbursementApplication',
                name: 'ReimbursementApplication',
                component: () => import('@/views/adminiStration/reimbursementApplication/index.vue'),
                meta: {
                    title: '报销申请',
                    noKeepAlive: true,
                    guard: ['ReimbursementApplication'],
                },
            },
            {
                path: 'reimbursementApplicationContent',
                name: 'ReimbursementApplicationContent',
                component: () => import('@/views/adminiStration/reimbursementApplication/content.vue'),
                meta: {
                    title: '报销申请编辑',
                    activeMenu: '/adminiStration/reimbursementApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ReimbursementApplicationContent'],
                },
            },
            {
                path: 'reimbursementApplicationApproavl',
                name: 'ReimbursementApplicationApproavl',
                component: () => import('@/views/adminiStration/reimbursementApplication/approval.vue'),
                meta: {
                    title: '报销申请审批',
                    activeMenu: '/adminiStration/reimbursementApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ReimbursementApplicationApproavl'],
                },
            },
            {
                path: 'reimbursementApplicationDetail',
                name: 'ReimbursementApplicationDetail',
                component: () => import('@/views/adminiStration/reimbursementApplication/detail.vue'),
                meta: {
                    title: '报销申请详情',
                    activeMenu: '/adminiStration/reimbursementApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['ReimbursementApplicationDetail'],
                },
            },
            {
                path: 'businessApplication',
                name: 'BusinessApplication',
                component: () => import('@/views/adminiStration/businessApplication/index.vue'),
                meta: {
                    title: '出差申请',
                    noKeepAlive: true,
                    guard: ['BusinessApplication'],
                },
            },
            {
                path: 'businessApplicationContent',
                name: 'BusinessApplicationContent',
                component: () => import('@/views/adminiStration/businessApplication/content.vue'),
                meta: {
                    title: '出差申请编辑',
                    activeMenu: '/adminiStration/businessApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BusinessApplicationContent'],
                },
            },
            {
                path: 'businessApplicationApproavl',
                name: 'BusinessApplicationApproavl',
                component: () => import('@/views/adminiStration/businessApplication/approval.vue'),
                meta: {
                    title: '出差申请审批',
                    activeMenu: '/adminiStration/businessApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BusinessApplicationApproavl'],
                },
            },
            {
                path: 'businessApplicationDetail',
                name: 'BusinessApplicationDetail',
                component: () => import('@/views/adminiStration/businessApplication/detail.vue'),
                meta: {
                    title: '出差申请详情',
                    activeMenu: '/adminiStration/businessApplication',
                    hidden: true,
                    dynamicNewTab: true,
                    guard: ['BusinessApplicationDetail'],
                },
            },
            {
                path: 'vehicleManagement',
                name: 'VehicleManagement',
                meta: {
                    title: '车辆管理',
                    guard: ['VehicleManagement'],
                },
                children: [
                    {
                        path: 'vehicleRegistration',
                        name: 'VehicleRegistration',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleRegistration/index.vue'),
                        meta: {
                            title: '车辆信息登记',
                            noKeepAlive: true,
                            guard: ['VehicleRegistration'],
                        },
                    },
                    {
                        path: 'vehicleRegistrationContent',
                        name: 'VehicleRegistrationContent',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleRegistration/content.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleRegistration',
                            hidden: true,
                            dynamicNewTab: true,
                            title: '车辆信息登记编辑页',
                            guard: ['VehicleRegistrationContent'],
                        },
                    },
                    {
                        path: 'vehicleRegistrationDetail',
                        name: 'VehicleRegistrationDetail',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleRegistration/detail.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleRegistration',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '车辆信息登记详情页',
                            guard: ['VehicleRegistrationDetail'],
                        },
                    },
                    {
                        path: 'vehicleRegistrationApproval',
                        name: 'VehicleRegistrationApproval',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleRegistration/approval.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleRegistration',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '车辆信息登记审批页',
                            guard: ['VehicleRegistrationApproval'],
                        },
                    },
                    {
                        path: 'vehicleContract',
                        name: 'VehicleContract',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleContract/index.vue'),
                        meta: {
                            title: '车险合同登记',
                            noKeepAlive: true,
                            guard: ['VehicleContract'],
                        },
                    },
                    {
                        path: 'vehicleContractContent',
                        name: 'VehicleContractContent',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleContract/content.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleContract',
                            hidden: true,
                            dynamicNewTab: true,
                            title: '车险合同登记编辑页',
                            guard: ['VehicleContractContent'],
                        },
                    },
                    {
                        path: 'vehicleContractDetail',
                        name: 'VehicleContractDetail',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleContract/detail.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleContract',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '车险合同登记详情页',
                            guard: ['VehicleContractDetail'],
                        },
                    },
                    {
                        path: 'vehicleContractApproval',
                        name: 'VehicleContractApproval',
                        component: () => import('@/views/adminiStration/vehicleManagement/vehicleContract/approval.vue'),
                        meta: {
                            activeMenu: '/adminiStration/vehicleManagement/vehicleContract',
                            hidden: true,
                            dynamicNewTab: true,
                            noKeepAlive: true,
                            title: '车险合同登记审批页',
                            guard: ['VehicleContractApproval'],
                        },
                    },
                ],
            },
        ],
    },
    // {
    //   path: '/approvalManagement',
    //   name: 'ApprovalManagement',
    //   component: Layout,
    //   meta: {
    //     title: '审批管理',
    //     hidden: true,
    //     icon: 'settings-5-line',
    //     guard: ['ApprovalManagement'],
    //   },
    //   children: [
    //     // {
    //     //   path: 'vehicleApplication',
    //     //   name: 'VehicleApplication',
    //     //   component: () =>
    //     //     import('@/views/approvalManagement/vehicleApplication/index.vue'),
    //     //   meta: {
    //     //     title: '用车申请',
    //     //     guard: ['VehicleApplication'],
    //     //   },
    //     // },
    //     // {
    //     //   path: 'leaveApplication',
    //     //   name: 'LeaveApplication',
    //     //   component: () =>
    //     //     import('@/views/approvalManagement/vehicleApplication/index.vue'),
    //     //   meta: {
    //     //     title: '请假申请',
    //     //     guard: ['LeaveApplication'],
    //     //   },
    //     // },
    //     // {
    //     //   path: 'borrowingApplication',
    //     //   name: 'BorrowingApplication',
    //     //   component: () =>
    //     //     import('@/views/approvalManagement/vehicleApplication/index.vue'),
    //     //   meta: {
    //     //     title: '借支申请',
    //     //     guard: ['BorrowingApplication'],
    //     //   },
    //     // },
    //     // {
    //     //   path: 'paymentApplication',
    //     //   name: 'PaymentApplication',
    //     //   component: () =>
    //     //     import('@/views/approvalManagement/vehicleApplication/index.vue'),
    //     //   meta: {
    //     //     title: '支付申请',
    //     //     guard: ['PaymentApplication'],
    //     //   },
    //     // },
    //     // {
    //     //   path: 'reimApplication',
    //     //   name: 'ReimApplication',
    //     //   component: () =>
    //     //     import('@/views/approvalManagement/vehicleApplication/index.vue'),
    //     //   meta: {
    //     //     title: '报销申请',
    //     //     guard: ['ReimApplication'],
    //     //   },
    //     // },
    //   ],
    // },
    {
        path: '/system',
        name: 'System',
        component: Layout,
        meta: {
            title: '基础数据',
            icon: 'function-line',
            guard: ['System'],
        },
        children: [
            {
                path: 'projectTypeList',
                name: 'ProjectTypeList',
                component: () => import('@/views/system/project/index.vue'),
                meta: {
                    title: '项目类型',
                    guard: ['ProjectTypeList'],
                },
            },
            {
                path: 'phase',
                name: 'Phase',
                component: () => import('@/views/system/phase/index.vue'),
                meta: {
                    title: '项目状态',
                    guard: ['Phase'],
                },
            },
            {
                path: 'contract',
                name: 'Contract',
                component: () => import('@/views/system/contract/index.vue'),
                meta: {
                    title: '合同类型',
                    guard: ['Contract'],
                },
            },
            {
                path: 'bid',
                name: 'Bid',
                component: () => import('@/views/system/bid/index.vue'),
                meta: {
                    title: '投标类型',
                    guard: ['Bid'],
                },
            },
            {
                path: 'operate',
                name: 'Operate',
                component: () => import('@/views/system/operate/index.vue'),
                meta: {
                    title: '经营类型',
                    guard: ['Operate'],
                },
            },
            {
                path: 'visa',
                name: 'Visa',
                component: () => import('@/views/system/visa/index.vue'),
                meta: {
                    title: '签证类型',
                    guard: ['Visa'],
                },
            },
            {
                path: 'meetingType',
                name: 'MeetingType',
                component: () => import('@/views/system/meeting/index.vue'),
                meta: {
                    title: '会议类型',
                    guard: ['MeetingType'],
                },
            },
            {
                path: 'unit',
                name: 'Unit',
                component: () => import('@/views/system/unit/index.vue'),
                meta: {
                    title: '单位类型',
                    guard: ['Unit'],
                },
            },
            {
                path: 'unitmes',
                name: 'Unitmes',
                component: () => import('@/views/system/unitmes/index.vue'),
                meta: {
                    title: '单位信息',
                    guard: ['Unitmes'],
                },
            },
            {
                path: 'supplier',
                name: 'Supplier',
                component: () => import('@/views/system/supplier/index.vue'),
                meta: {
                    title: '供应商',
                    guard: ['Supplier'],
                },
            },
            {
                path: 'pay_type',
                name: 'Pay_type',
                component: () => import('@/views/system/pay_type/index.vue'),
                meta: {
                    title: '付款类型',
                    guard: ['Pay_type'],
                },
            },
            {
                path: 'payment_type',
                name: 'Payment_type',
                component: () => import('@/views/system/payment_type/index.vue'),
                meta: {
                    title: '收款类型',
                    guard: ['Payment_type'],
                },
            },
            {
                path: 'lendType',
                name: 'LendType',
                component: () => import('@/views/system/lendType/index.vue'),
                meta: {
                    title: '借支类型',
                    guard: ['LendType'],
                },
            },
            {
                path: 'costChangeType',
                name: 'CostChangeType',
                component: () => import('@/views/system/costChangeType/index.vue'),
                meta: {
                    title: '费用变更类型',
                    guard: ['CostChangeType'],
                },
            },
            {
                path: 'quality',
                name: 'Quality',
                component: () => import('@/views/system/quality/index.vue'),
                meta: {
                    title: '质量检查类型',
                    guard: ['Quality'],
                },
            },
            {
                path: 'danger',
                name: 'Danger',
                component: () => import('@/views/system/danger/index.vue'),
                meta: {
                    title: '隐患等级',
                    guard: ['Danger'],
                },
            },
            {
                path: 'security',
                name: 'Security',
                component: () => import('@/views/system/security/index.vue'),
                meta: {
                    title: '安全检查类型',
                    guard: ['Security'],
                },
            },
            {
                path: 'invoice',
                name: 'Invoice',
                component: () => import('@/views/system/invoice/index.vue'),
                meta: {
                    title: '发票类型',
                    guard: ['Invoice'],
                },
            },
            {
                path: 'charging',
                name: 'Charging',
                component: () => import('@/views/system/charging/index.vue'),
                meta: {
                    title: '计费单位',
                    guard: ['Charging'],
                },
            },
            {
                path: 'metering',
                name: 'Metering',
                component: () => import('@/views/system/metering/index.vue'),
                meta: {
                    title: '计量单位',
                    guard: ['Metering'],
                },
            },
            {
                path: 'seal',
                name: 'Seal',
                component: () => import('@/views/system/seal/index.vue'),
                meta: {
                    title: '印章类型',
                    guard: ['Seal'],
                },
            },
            {
                path: 'idtype',
                name: 'Idtype',
                component: () => import('@/views/system/idtype/index.vue'),
                meta: {
                    title: '证件类型',
                    guard: ['Idtype'],
                },
            },
            {
                path: 'post',
                name: 'Post',
                component: () => import('@/views/system/post/index.vue'),
                meta: {
                    title: '岗位',
                    guard: ['Post'],
                },
            },
            {
                path: 'education',
                name: 'Education',
                component: () => import('@/views/system/education/index.vue'),
                meta: {
                    title: '学历类型',
                    guard: ['Education'],
                },
            },
            {
                path: 'nation',
                name: 'Nation',
                component: () => import('@/views/system/nation/index.vue'),
                meta: {
                    title: '民族',
                    guard: ['Nation'],
                },
            },
            {
                path: 'politics',
                name: 'Politics',
                component: () => import('@/views/system/politics/index.vue'),
                meta: {
                    title: '政治面貌',
                    guard: ['Politics'],
                },
            },
            {
                path: 'household',
                name: 'Household',
                component: () => import('@/views/system/household/index.vue'),
                meta: {
                    title: '户口性质',
                    guard: ['Household'],
                },
            },
            {
                path: 'depart',
                name: 'Depart',
                component: () => import('@/views/system/depart/index.vue'),
                meta: {
                    title: '离职原因',
                    guard: ['Depart'],
                },
            },
            {
                path: 'employee',
                name: 'Employee',
                component: () => import('@/views/system/employee/index.vue'),
                meta: {
                    title: '员工合同类型',
                    guard: ['Employee'],
                },
            },
            {
                path: 'dismissType',
                name: 'DismissType',
                component: () => import('@/views/system/dismissType/index.vue'),
                meta: {
                    title: '离职类别',
                    guard: ['DismissType'],
                },
            },
            {
                path: 'financeType',
                name: 'FinanceType',
                component: () => import('@/views/system/financeType/index.vue'),
                meta: {
                    title: '成本类别',
                    guard: ['FinanceType'],
                },
            },
            {
                path: 'costSubject',
                name: 'CostSubject',
                component: () => import('@/views/system/costSubject/index.vue'),
                meta: {
                    title: '成本科目',
                    guard: ['CostSubject'],
                },
            },
            {
                path: 'bankRecord',
                name: 'BankRecord',
                component: () => import('@/views/system/bankRecord/index.vue'),
                meta: {
                    title: '银行档案',
                    guard: ['BankRecord'],
                },
            },
            {
                path: 'expenseType',
                name: 'ExpenseType',
                component: () => import('@/views/system/expenseType/index.vue'),
                meta: {
                    title: '费用类型',
                    guard: ['ExpenseType'],
                },
            },
            {
                path: 'taxRate',
                name: 'TaxRate',
                component: () => import('@/views/system/taxRate/index.vue'),
                meta: {
                    title: '税率',
                    noKeepAlive: true,
                    guard: ['TaxRate'],
                },
            },
            {
                path: 'businessPeriod',
                name: 'BusinessPeriod',
                component: () => import('@/views/system/businessPeriod/index.vue'),
                meta: {
                    title: '业务期间',
                    noKeepAlive: true,
                    guard: ['BusinessPeriod'],
                },
            },
            {
                path: 'administrativeRegion',
                name: 'AdministrativeRegion',
                component: () => import('@/views/system/administrativeRegion/index.vue'),
                meta: {
                    title: '行政区域',
                    noKeepAlive: true,
                    guard: ['AdministrativeRegion'],
                },
            },
            // Expense type
            // {
            //   path: 'paytype',
            //   name: 'paytype',
            //   component: () => import('@/views/system/paytype/index.vue'),
            //   meta: {
            //     title: '支付方式',
            //     guard: ['paytype'],
            //   },
            // },
            // {
            //   path: 'taxpayment',
            //   name: 'taxpayment',
            //   component: () => import('@/views/system/taxpayment/index.vue'),
            //   meta: {
            //     title: '税款名称',
            //     guard: ['taxpayment'],
            //   },
            // },
            // {
            //   path: 'contractPayment',
            //   name: 'contractPayment',
            //   component: () => import('@/views/system/contractPayment/index.vue'),
            //   meta: {
            //     title: '合同收款节点',
            //     guard: ['contractPayment'],
            //   },
            // },
            // {
            //   path: 'engineerVisaType',
            //   name: 'engineerVisaType',
            //   component: () => import('@/views/system/engineerVisaType/index.vue'),
            //   meta: {
            //     title: '工程签证类型',
            //     guard: ['engineerVisaType'],
            //   },
            // },
        ],
    },
    {
        path: '/setting',
        name: 'Setting',
        component: Layout,
        meta: {
            title: '系统设置',
            icon: 'sun-line',
            guard: ['Setting'],
        },
        children: [
            {
                path: 'employeeManagement',
                name: 'EmployeeManagement',
                component: () => import('@/views/personnel/employee/index.vue'),
                meta: {
                    title: '用户管理',
                    guard: ['EmployeeManagement'],
                },
            },
            {
                path: 'enterprise',
                name: 'Enterprise',
                component: () => import('@/views/setting/enterprise/index.vue'),
                meta: {
                    title: '企业信息',
                    guard: ['Enterprise'],
                },
            },
            {
                path: 'proOrganizational',
                name: 'ProOrganizational',
                component: () => import('@/views/setting/proOrganizational/index.vue'),
                meta: {
                    title: '项目组织架构',
                    guard: ['ProOrganizational'],
                },
            },
            {
                path: 'settingContent',
                name: 'SettingContent',
                component: () => import('@/views/setting/proOrganizational/settingContent.vue'),
                meta: {
                    activeMenu: '/setting/proOrganizational',
                    tabHidden: true,
                    hidden: true,
                    dynamicNewTab: true,
                    noKeepAlive: false,
                    title: '项目组织架构审批流配置',
                    guard: ['SettingContent'],
                },
            },
            {
                path: 'organizational',
                name: 'Organizational',
                component: () => import('@/views/setting/organizational/index.vue'),
                meta: {
                    title: '企业组织架构',
                    guard: ['Organizational'],
                },
            },
            {
                path: 'limit',
                name: 'Limit',
                component: () => import('@/views/setting/limit/index.vue'),
                meta: {
                    title: '角色权限',
                    noKeepAlive: true,
                    guard: ['Limit'],
                },
            },
            {
                path: 'addRole',
                name: 'AddRole',
                component: () => import('@/views/setting/limit/AddRole.vue'),
                meta: {
                    title: '添加角色权限',
                    activeMenu: '/setting/limit',
                    guard: ['AddRole'],
                    dynamicNewTab: true,
                    hidden: true,
                },
            },
            {
                path: 'approve',
                name: 'Approve',
                component: () => import('@/views/setting/approve/index.vue'),
                meta: {
                    title: '审批流程设置',
                    guard: ['Approve'],
                },
            },
            {
                path: 'addApprove',
                name: 'AddApprove',
                component: () => import('@/views/setting/approve/setting.vue'),
                meta: {
                    title: '添加审批流程',
                    activeMenu: '/setting/approve',
                    dynamicNewTab: true,
                    noKeepAlive: false,
                    guard: ['AddApprove'],
                    hidden: true,
                },
            },
            {
                path: 'operationLog',
                name: 'OperationLog',
                component: () => import('@/views/setting/operationLog/index.vue'),
                meta: {
                    title: '操作日志',
                    guard: ['OperationLog'],
                },
            },
        ],
    },
    {
        path: '/merchantManagement',
        name: 'MerchantManagement',
        component: Layout,
        meta: {
            title: '客商管理',
            icon: 'group-line',
            guard: ['MerchantManagement'],
        },
        children: [
            {
                path: 'customerProfile',
                name: 'CustomerProfile',
                component: () => import('@/views/merchantManagement/customerProfile/index.vue'),
                meta: {
                    title: '客户档案',
                    noKeepAlive: true,
                    guard: ['CustomerProfile'],
                },
            },
            {
                path: 'supplierProfile',
                name: 'SupplierProfile',
                component: () => import('@/views/merchantManagement/supplierProfile/index.vue'),
                meta: {
                    title: '供应商档案',
                    noKeepAlive: true,
                    guard: ['SupplierProfile'],
                },
            },
            {
                path: 'laborTeams',
                name: 'LaborTeams',
                component: () => import('@/views/merchantManagement/laborTeams/index.vue'),
                meta: {
                    title: '劳务队伍',
                    noKeepAlive: true,
                    guard: ['LaborTeams'],
                },
            },
            {
                path: 'laborTeamsContent',
                name: 'LaborTeamsContent',
                component: () => import('@/views/merchantManagement/laborTeams/content.vue'),
                meta: {
                    title: '劳务队伍编辑页',
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/merchantManagement/laborTeams',
                    guard: ['LaborTeamsContent'],
                },
            },
            {
                path: 'laborTeamsDetail',
                name: 'LaborTeamsDetail',
                component: () => import('@/views/merchantManagement/laborTeams/detail.vue'),
                meta: {
                    title: '劳务队伍详情页',
                    hidden: true,
                    dynamicNewTab: true,
                    activeMenu: '/merchantManagement/laborTeams',
                    guard: ['LaborTeamsDetail'],
                },
            },
        ],
    },
    {
        path: '/businessManagement',
        name: 'BusinessManagement',
        component: Layout,
        meta: {
            title: '合同管理',
            icon: 'clipboard-line',
            guard: ['BusinessManagement'],
        },
        children: [
            {
                path: 'revenueContractManagement',
                name: 'RevenueContractManagement',
                meta: {
                    title: '收入合同',
                    noKeepAlive: true,
                    guard: ['RevenueContractManagement'],
                },
                children: [
                    {
                        path: 'revenueContract',
                        name: 'RevenueContract',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContract/index.vue'),
                        meta: {
                            title: '收入合同',
                            noKeepAlive: true,
                            guard: ['RevenueContract'],
                        },
                    },
                    {
                        path: 'revenueContractContent',
                        name: 'RevenueContractContent',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContract/content.vue'),
                        meta: {
                            title: '收入合同编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractContent'],
                        },
                    },
                    {
                        path: 'revenueContractDetail',
                        name: 'RevenueContractDetail',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContract/detail.vue'),
                        meta: {
                            title: '收入合同详情页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractDetail'],
                        },
                    },
                    {
                        path: 'revenueContractRevise',
                        name: 'RevenueContractRevise',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContract/revise.vue'),
                        meta: {
                            title: '收入合同修订页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractRevise'],
                        },
                    },
                    {
                        path: 'revenueContractApproval',
                        name: 'RevenueContractApproval',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContract/approval.vue'),
                        meta: {
                            title: '收入合同审批页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractApproval'],
                        },
                    },
                    {
                        path: 'revenueContractMetaphase',
                        name: 'RevenueContractMetaphase',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContractMetaphase/index.vue'),
                        meta: {
                            title: '期中计量',
                            noKeepAlive: true,
                            guard: ['RevenueContractMetaphase'],
                        },
                    },
                    {
                        path: 'revenueContractMetaphaseContent',
                        name: 'RevenueContractMetaphaseContent',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContractMetaphase/content.vue'),
                        meta: {
                            title: '期中计量编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractMetaphaseContent'],
                        },
                    },
                    {
                        path: 'revenueContractMetaphaseDetail',
                        name: 'RevenueContractMetaphaseDetail',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContractMetaphase/detail.vue'),
                        meta: {
                            title: '期中计量详情页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractMetaphaseDetail'],
                        },
                    },
                    {
                        path: 'revenueContractMetaphaseRevise',
                        name: 'RevenueContractMetaphaseRevise',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContractMetaphase/revise.vue'),
                        meta: {
                            title: '期中计量修订页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractMetaphaseRevise'],
                        },
                    },
                    {
                        path: 'revenueContractMetaphaseApproval',
                        name: 'RevenueContractMetaphaseApproval',
                        component: () => import('@/views/businessManagement/revenueContractManagement/revenueContractMetaphase/approval.vue'),
                        meta: {
                            title: '期中计量审批页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/revenueContract',
                            guard: ['RevenueContractMetaphaseApproval'],
                        },
                    },
                ],
            },
            {
                path: 'procureContractSubject',
                name: 'ProcureContractSubject',
                meta: {
                    title: '采购合同',
                    guard: ['ProcureContractSubject'],
                },
                children: [
                    {
                        path: 'procureContract',
                        name: 'ProcureContract',
                        component: () => import('@/views/businessManagement/procureContractSubject/procureContract/index.vue'),
                        meta: {
                            title: '采购合同',
                            noKeepAlive: true,
                            guard: ['ProcureContract'],
                        },
                    },
                    {
                        path: 'procureContractContent',
                        name: 'ProcureContractContent',
                        component: () => import('@/views/businessManagement/procureContractSubject/procureContract/content.vue'),
                        meta: {
                            title: '采购合同编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/procureContract',
                            guard: ['ProcureContractContent'],
                        },
                    },
                    {
                        path: 'procureContractDetail',
                        name: 'ProcureContractDetail',
                        component: () => import('@/views/businessManagement/procureContractSubject/procureContract/detail.vue'),
                        meta: {
                            title: '采购合同详情页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/procureContract',
                            guard: ['ProcureContractDetail'],
                        },
                    },
                    {
                        path: 'procureContractApproval',
                        name: 'ProcureContractApproval',
                        component: () => import('@/views/businessManagement/procureContractSubject/procureContract/approval.vue'),
                        meta: {
                            title: '采购合同审批页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/procureContract',
                            guard: ['ProcureContractApproval'],
                        },
                    },
                    {
                        path: 'purchaseSettlement',
                        name: 'PurchaseSettlement',
                        component: () => import('@/views/businessManagement/procureContractSubject/purchaseSettlement/index.vue'),
                        meta: {
                            title: '采购结算',
                            noKeepAlive: true,
                            guard: ['PurchaseSettlement'],
                        },
                    },
                    {
                        path: 'purchaseSettlementContent',
                        name: 'PurchaseSettlementContent',
                        component: () => import('@/views/businessManagement/procureContractSubject/purchaseSettlement/content.vue'),
                        meta: {
                            title: '采购结算编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/purchaseSettlement',
                            guard: ['PurchaseSettlementContent'],
                        },
                    },
                    {
                        path: 'purchaseSettlementDetail',
                        name: 'PurchaseSettlementDetail',
                        component: () => import('@/views/businessManagement/procureContractSubject/purchaseSettlement/detail.vue'),
                        meta: {
                            title: '采购结算详情页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/purchaseSettlement',
                            guard: ['PurchaseSettlementDetail'],
                        },
                    },
                    {
                        path: 'purchaseSettlementApproval',
                        name: 'PurchaseSettlementApproval',
                        component: () => import('@/views/businessManagement/procureContractSubject/purchaseSettlement/approval.vue'),
                        meta: {
                            title: '采购结算审批页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/procureContractContent/purchaseSettlement',
                            guard: ['PurchaseSettlementApproval'],
                        },
                    },
                ],
            },
            {
                path: 'equipmentLeaseAgreement',
                name: 'EquipmentLeaseAgreement',
                meta: {
                    title: '设备租赁合同',
                    guard: ['EquipmentLeaseAgreement'],
                },
                children: [
                    {
                        path: 'equipmentContract',
                        name: 'EquipmentContract',
                        component: () => import('@/views/businessManagement/equipmentLeaseAgreement/equipmentContract/index.vue'),
                        meta: {
                            title: '设备租赁合同',
                            noKeepAlive: true,
                            guard: ['EquipmentContract'],
                        },
                    },
                    {
                        path: 'equipmentContractContent',
                        name: 'EquipmentContractContent',
                        component: () => import('@/views/businessManagement/equipmentLeaseAgreement/equipmentContract/content.vue'),
                        meta: {
                            title: '设备租赁合同编辑页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/equipmentLeaseAgreement/equipmentContract',
                            guard: ['EquipmentContractContent'],
                        },
                    },
                    {
                        path: 'equipmentContractDetail',
                        name: 'EquipmentContractDetail',
                        component: () => import('@/views/businessManagement/equipmentLeaseAgreement/equipmentContract/detail.vue'),
                        meta: {
                            title: '设备租赁合同详情页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/equipmentLeaseAgreement/equipmentContract',
                            guard: ['EquipmentContractDetail'],
                        },
                    },
                    {
                        path: 'equipmentContractApproval',
                        name: 'EquipmentContractApproval',
                        component: () => import('@/views/businessManagement/equipmentLeaseAgreement/equipmentContract/approval.vue'),
                        meta: {
                            title: '设备租赁合同审批页',
                            hidden: true,
                            dynamicNewTab: true,
                            activeMenu: '/businessManagement/equipmentLeaseAgreement/equipmentContract',
                            guard: ['EquipmentContractApproval'],
                        },
                    },
                ],
            },
        ],
    },
];
