/*
 * @Author: Try
 * @Date: 2023-09-25 10:50:29
 * @LastEditTime: 2023-10-18 09:59:47
 * @LastEditors: Do not edit
 * @FilePath: \shulong_tenant_pc\src\store\index.ts
 * @Description: 头部注释配置模板
 */
/**
 * @description 导入所有 pinia 模块，请勿修改。
 */
const pinia = createPinia();
export function setupStore(app) {
  app.use(pinia);
}
export default pinia;