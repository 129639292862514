/*
 * @Date: 2023-03-29 15:25:37
 * @LastEditors: Seven
 * @LastEditTime: 2024-01-16 15:39:07
 * @FilePath: /Workflow-Vue3/src/utils/const.js
 */

export const bgColors = ['87, 106, 149', '255, 148, 62', '50, 150, 250']
export const placeholderList = ['发起人', '审批人', '抄送人']

export const setTypes = [
  { value: 4, label: '发起人自选' },
  { value: 1, label: '指定人' },
  { value: 2, label: '发起人主管' },
  { value: 5, label: '发起人自己' },
  // { value: 7, label: '连续多级主管' },
  { value: 8, label: '角色' },
]

export const selectModes = [
  { value: 1, label: '选一个人' },
  { value: 2, label: '选多个人' },
]

export const selectRanges = [
  { value: 1, label: '全公司' },
  { value: 2, label: '指定成员' },
  { value: 3, label: '指定角色' },
]

export const optTypes = [
  { value: '1', label: '小于' },
  { value: '2', label: '大于' },
  // { value: '3', label: '小于等于' },
  { value: '4', label: '等于' },
  // { value: '5', label: '大于等于' },
  // { value: '6', label: '介于两个数之间' },
]

export const opt1s = [
  { value: '<', label: '<' },
  { value: '≤', label: '≤' },
]

/**
 * @description: 条件分支
 */
export const conditionalBranch = {
  4: [
    {
      columnId: '1',
      columnName: 'amount',
      columnType: 'Double',
      fixedDownBoxValue: '',
      laber: '付款金额',
      fieldType: 'number',
    },
  ],
  APPROVED: { value: 'approved', label: '已批准' },
  REJECTED: { value: 'rejected', label: '已拒绝' },
}

export function validateCondition(key) {
  return Object.keys(conditionalBranch).includes(key)
}
