import "core-js/modules/es.array.push.js";
import addNode from './addNode.vue';
import { onMounted, ref, watch, getCurrentInstance, computed } from 'vue';
import $func from '../utils/$func';
import { useUserStore } from '@/store/modules/user';
import { useStore } from '@/store/modules/workFlow';
import { bgColors, placeholderList } from '../utils/const';

import { toRef } from 'vue';
export default {
  __name: 'nodeWalk',
  props: {
    nodeConfig: {
      type: Object,
      default: () => ({})
    },
    flowPermission: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:flowPermission', 'update:nodeConfig'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    __expose();
    const usrStore = useUserStore();
    const _uid = getCurrentInstance().uid;
    const props = __props;
    const propsNodeConfig = toRef(props, 'nodeConfig');
    const defaultText = computed(() => {
      return placeholderList[propsNodeConfig.value.type];
    });
    const showText = computed(() => {
      if (propsNodeConfig.value.type == 0) return `${usrStore.company_name}`;
      if (propsNodeConfig.value.type == 1) return $func.setApproverStr(propsNodeConfig.value);
      return $func.copyerStr(propsNodeConfig.value);
    });
    const isInputList = ref([]);
    const isInput = ref(false);
    const resetConditionNodesErr = () => {
      for (let i = 0; i < propsNodeConfig.value.conditionNodes.length; i++) {
        propsNodeConfig.value.conditionNodes[i].error = $func.conditionStr(propsNodeConfig.value, i) == '请设置条件';
      }
    };
    onMounted(() => {
      if (propsNodeConfig.value.type == 1) {
        propsNodeConfig.value.error = !$func.setApproverStr(propsNodeConfig.value);
      } else if (propsNodeConfig.value.type == 2) {
        propsNodeConfig.value.error = !$func.copyerStr(propsNodeConfig.value);
      } else if (propsNodeConfig.value.type == 4) {
        resetConditionNodesErr();
      }
    });
    const emits = __emit;
    const store = useStore();
    const {
      setPromoter,
      setApprover,
      setCopyer,
      setCondition,
      setFlowPermission,
      setApproverConfig,
      setCopyerConfig,
      setConditionsConfig
    } = store;
    const isTried = computed(() => store.isTried);
    const flowPermission1 = computed(() => store.flowPermission1);
    const approverConfig1 = computed(() => store.approverConfig1);
    const copyerConfig1 = computed(() => store.copyerConfig1);
    const conditionsConfig1 = computed(() => store.conditionsConfig1);
    watch(flowPermission1, flow => {
      if (flow.flag && flow.id === _uid) {
        emits('update:flowPermission', flow.value);
      }
    });
    watch(approverConfig1, approver => {
      if (approver.flag && approver.id === _uid) {
        emits('update:nodeConfig', approver.value);
      }
    });
    watch(copyerConfig1, copyer => {
      if (copyer.flag && copyer.id === _uid) {
        emits('update:nodeConfig', copyer.value);
      }
    });
    watch(conditionsConfig1, condition => {
      if (condition.flag && condition.id === _uid) {
        emits('update:nodeConfig', condition.value);
      }
    });
    const clickEvent = index => {
      if (index || index === 0) {
        isInputList.value[index] = true;
      } else {
        isInput.value = true;
      }
    };
    const blurEvent = index => {
      if (index || index === 0) {
        isInputList.value[index] = false;
        propsNodeConfig.value.conditionNodes[index].nodeName = propsNodeConfig.value.conditionNodes[index].nodeName || '条件';
      } else {
        isInput.value = false;
        propsNodeConfig.value.nodeName = propsNodeConfig.value.nodeName || defaultText;
      }
    };
    const delNode = () => {
      emits('update:nodeConfig', propsNodeConfig.value.childNode);
    };
    const addTerm = () => {
      const len = propsNodeConfig.value.conditionNodes.length + 1;
      propsNodeConfig.value.conditionNodes.push({
        nodeName: `条件${len}`,
        type: 3,
        error: true,
        priorityLevel: len,
        conditionList: [],
        nodeUserList: [],
        childNode: null
      });
      resetConditionNodesErr();
      emits('update:nodeConfig', propsNodeConfig.value);
    };
    const delTerm = index => {
      propsNodeConfig.value.conditionNodes.splice(index, 1);
      propsNodeConfig.value.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1;
        item.nodeName = `条件${index + 1}`;
      });
      resetConditionNodesErr();
      emits('update:nodeConfig', propsNodeConfig.value);
      if (propsNodeConfig.value.conditionNodes.length == 1) {
        if (propsNodeConfig.value.childNode) {
          if (propsNodeConfig.value.conditionNodes[0].childNode) {
            reData(propsNodeConfig.value.conditionNodes[0].childNode, propsNodeConfig.value.childNode);
          } else {
            propsNodeConfig.value.conditionNodes[0].childNode = propsNodeConfig.value.childNode;
          }
        }
        emits('update:nodeConfig', propsNodeConfig.value.conditionNodes[0].childNode);
      }
    };
    const reData = (data, addData) => {
      if (!data.childNode) {
        data.childNode = addData;
      } else {
        reData(data.childNode, addData);
      }
    };
    const setPerson = priorityLevel => {
      const {
        type
      } = propsNodeConfig.value;
      if (type == 0) {
        setPromoter(true);
        setFlowPermission({
          value: props.flowPermission,
          flag: false,
          id: _uid
        });
      } else if (type == 1) {
        setApprover(true);
        setApproverConfig({
          value: {
            ...JSON.parse(JSON.stringify(propsNodeConfig.value)),
            ...{
              settype: propsNodeConfig.value.settype ? propsNodeConfig.value.settype : 1
            }
          },
          flag: false,
          id: _uid
        });
      } else if (type == 2) {
        setCopyer(true);
        setCopyerConfig({
          value: JSON.parse(JSON.stringify(propsNodeConfig.value)),
          flag: false,
          id: _uid
        });
      } else {
        setCondition(true);
        setConditionsConfig({
          value: JSON.parse(JSON.stringify(propsNodeConfig.value)),
          priorityLevel,
          flag: false,
          id: _uid
        });
      }
    };
    const arrTransfer = (index, type = 1) => {
      //向左-1,向右1
      propsNodeConfig.value.conditionNodes[index] = propsNodeConfig.value.conditionNodes.splice(index + type, 1, propsNodeConfig.value.conditionNodes[index])[0];
      propsNodeConfig.value.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1;
      });
      resetConditionNodesErr();
      emits('update:nodeConfig', propsNodeConfig.value);
    };
    const __returned__ = {
      usrStore,
      _uid,
      props,
      propsNodeConfig,
      defaultText,
      showText,
      isInputList,
      isInput,
      resetConditionNodesErr,
      emits,
      store,
      setPromoter,
      setApprover,
      setCopyer,
      setCondition,
      setFlowPermission,
      setApproverConfig,
      setCopyerConfig,
      setConditionsConfig,
      isTried,
      flowPermission1,
      approverConfig1,
      copyerConfig1,
      conditionsConfig1,
      clickEvent,
      blurEvent,
      delNode,
      addTerm,
      delTerm,
      reData,
      setPerson,
      arrTransfer,
      addNode,
      onMounted,
      ref,
      watch,
      getCurrentInstance,
      computed,
      get $func() {
        return $func;
      },
      get useUserStore() {
        return useUserStore;
      },
      get useStore() {
        return useStore;
      },
      get bgColors() {
        return bgColors;
      },
      get placeholderList() {
        return placeholderList;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};