function All() {}
All.prototype = {
  timer: '',
  debounce(fn, delay = 500) {
    const _this = this
    return function (arg) {
      //获取函数的作用域和变量
      const that = this
      const args = arg
      clearTimeout(_this.timer) // 清除定时器
      _this.timer = setTimeout(function () {
        fn.call(that, args)
      }, delay)
    }
  },
  setCookie(val) {
    //cookie设置[{key:value}]、获取key、清除['key1','key2']
    for (let i = 0, len = val.length; i < len; i++) {
      for (const key in val[i]) {
        document.cookie = `${key}=${encodeURIComponent(val[i][key])}; path=/`
      }
    }
  },
  getCookie(name) {
    const strCookie = document.cookie
    const arrCookie = strCookie.split('; ')
    for (let i = 0, len = arrCookie.length; i < len; i++) {
      const arr = arrCookie[i].split('=')
      if (name == arr[0]) {
        return decodeURIComponent(arr[1])
      }
    }
  },
  clearCookie(name) {
    const myDate = new Date()
    myDate.setTime(-1000) //设置时间
    for (let i = 0, len = name.length; i < len; i++) {
      document.cookie = `${name[i]}=''; path=/; expires=${myDate.toGMTString()}`
    }
  },
  arrToStr(arr) {
    if (arr) {
      return arr
        .map((item) => {
          return item.name
        })
        .toString()
    }
  },
  toggleClass(arr, elem, key = 'id') {
    return arr.some((item) => {
      return item[key] == elem[key]
    })
  },
  toChecked(arr, elem, key = 'id') {
    const isIncludes = this.toggleClass(arr, elem, key)
    !isIncludes ? arr.push(elem) : this.removeEle(arr, elem, key)
  },
  removeEle(arr, elem, key = 'id') {
    let includesIndex
    arr.map((item, index) => {
      if (item[key] == elem[key]) {
        includesIndex = index
      }
    })
    arr.splice(includesIndex, 1)
  },
  setApproverStr(nodeConfig) {
    if (nodeConfig.settype == 1) {
      if (nodeConfig.nodeUserList.length == 1) {
        return nodeConfig.nodeUserList[0].name
      } else if (nodeConfig.nodeUserList.length > 1) {
        if (nodeConfig.examineMode == 1) {
          return this.arrToStr(nodeConfig.nodeUserList)
        } else if (nodeConfig.examineMode == 2) {
          return `${nodeConfig.nodeUserList.length}人会签`
        } else if (nodeConfig.examineMode == 3) {
          return `${nodeConfig.nodeUserList.length}人或签`
        }
      }
    } else if (nodeConfig.settype == 2) {
      const level =
        nodeConfig.directorLevel == 1
          ? '直接主管'
          : `第${nodeConfig.directorLevel}级主管`
      if (nodeConfig.examineMode == 1) {
        return level
      } else if (nodeConfig.examineMode == 2) {
        return `${level}会签`
      } else if (nodeConfig.examineMode == 3) {
        return `${level}或签`
      }
    } else if (nodeConfig.settype == 4) {
      if (nodeConfig.selectRange == 1) {
        return '发起人自选'
      } else {
        if (nodeConfig.nodeUserList.length > 0) {
          if (nodeConfig.selectRange == 2) {
            return '发起人自选'
          } else {
            return `发起人从${nodeConfig.nodeUserList[0].name}中自选`
          }
        } else {
          return ''
        }
      }
    } else if (nodeConfig.settype == 5) {
      return '发起人自己'
    } else if (nodeConfig.settype == 7) {
      return `从直接主管到通讯录中级别最高的第${nodeConfig.examineEndDirectorLevel}个层级主管`
    } else if (nodeConfig.settype == 8) {
      if (nodeConfig.nodeUserList.length == 1) {
        return nodeConfig.nodeUserList[0].name
      }
    }
  },
  dealStr(str, obj) {
    const arr = []
    const list = str.split(',')
    for (const elem in obj) {
      list.map((item) => {
        if (item == elem) {
          arr.push(obj[elem].value)
        }
      })
    }
    return arr.join('或')
  },
  conditionStr(nodeConfig, index) {
    const { conditionList, nodeUserList } = nodeConfig.conditionNodes[index]
    if (conditionList.length == 0) {
      return index == nodeConfig.conditionNodes.length - 1 &&
        nodeConfig.conditionNodes[0].conditionList.length != 0
        ? '请设置条件'
        : '请设置条件'
    } else {
      let str = ''
      for (let i = 0; i < conditionList.length; i++) {
        const {
          columnId,
          columnType,
          fieldType,
          laber,
          optType,
          zdy1,
          opt1,
          zdy2,
          opt2,
          fixedDownBoxValue,
        } = conditionList[i]
        if (columnId == 0) {
          if (nodeUserList.length != 0) {
            str += '发起人属于：'
            str += `${nodeUserList
              .map((item) => {
                return item.name
              })
              .join('或')} 并且 `
          }
        }
        if (columnType == 'String' && fieldType == '3') {
          if (zdy1) {
            str += `${laber}属于：${this.dealStr(
              zdy1,
              JSON.parse(fixedDownBoxValue)
            )} 并且 `
          }
        }
        if (columnType == 'Double') {
          if (optType != 6 && zdy1) {
            const optTypeStr = ['', '<', '>', '≤', '=', '≥'][optType]
            str += `${laber} ${optTypeStr} ${zdy1} 并且 `
          } else if (optType == 6 && zdy1 && zdy2) {
            str += `${zdy1} ${opt1} ${laber} ${opt2} ${zdy2} 并且 `
          }
        }
      }
      return str ? str.substring(0, str.length - 4) : '请设置条件'
    }
  },
  copyerStr(nodeConfig) {
    if (nodeConfig.nodeUserList?.length != 0) {
      return this.arrToStr(nodeConfig.nodeUserList)
    } else {
      if (nodeConfig.set_type == 4) {
        return '发起人自选'
      }
    }
  },
  toggleStrClass(item, key) {
    const a = item.zdy1 ? item.zdy1.split(',') : []
    return a.some((item) => {
      return item == key
    })
  },
}

export default new All()
