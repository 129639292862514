/*
 * @Description:
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2023-10-26 16:54:13
 * @LastEditors: Seven
 * @LastEditTime: 2023-10-27 09:10:27
 */
export const useCostStore = defineStore('cost', {
  state: () => ({
    queryData: {
      analysis_id: '',
      project_id: '',
      type_id: '',
      project_name: '',
      curIndex: ''
    }
  }),
  getters: {
    getData: state => state.queryData
  },
  actions: {
    setData(data) {
      this.queryData = {
        ...data
      };
    }
  }
});