import { useSettingsStore } from '@/store/modules/settings';
import { debounce } from 'lodash-es';

import { defineComponent, ref, watch, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useEventListener } from '@vueuse/core';
const imports = require.context('./', true, /\.vue$/);
const Components = {};
imports.keys().filter(key => key !== './index.vue').forEach(key => {
  Components[key.replace(/(\/|\.|index.vue)/g, '')] = imports(key).default;
});
export default defineComponent({
  name: 'Layouts',
  components: Components,
  setup() {
    const settingsStore = useSettingsStore();
    const {
      device,
      collapse,
      theme
    } = storeToRefs(settingsStore);
    const {
      toggleDevice,
      foldSideBar,
      openSideBar,
      updateTheme
    } = settingsStore;
    const mobile = ref(false);
    let oldLayout = theme.value.layout;
    const resizeBody = debounce(() => {
      // console.log('navai', navigator.userAgent)
      const isMobile = /mobile/i.test(navigator.userAgent);
      mobile.value = isMobile;
      if (!isMobile) {
        if (document.body.getBoundingClientRect().width - 1 < 992) {
          foldSideBar();
        } else {
          if (process.env.NODE_ENV === 'development') {
            openSideBar();
          }
        }
      }
      // 以下是模板自带的代码，并且没有 debounce  逻辑就是当前屏幕窗口小于 992 会替换另外一个组件
      // mobile.value = document.body.getBoundingClientRect().width - 1 < 992
    }, 400);
    watch(mobile, val => {
      if (val) {
        oldLayout = theme.value.layout;
        foldSideBar();
        // }
      } else {
        if (process.env.NODE_ENV === 'development') {
          openSideBar();
        }
      }
      theme.value.layout = val ? 'vertical' : oldLayout;
      toggleDevice(val ? 'mobile' : 'desktop');
    });
    resizeBody();
    updateTheme();
    const cleanup = useEventListener('resize', () => {
      resizeBody();
    });
    onUnmounted(() => {
      if (mobile.value) theme.value.layout = oldLayout;
      cleanup();
    });
    return {
      theme,
      device,
      mobile,
      collapse,
      foldSideBar,
      openSideBar,
      toggleDevice
    };
  }
});