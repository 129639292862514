/*
 * @Date: 2022-08-25 14:13:11
 * @LastEditors: Seven
 * @LastEditTime: 2024-01-12 09:18:03
 * @FilePath: /Workflow-Vue3/src/store/index.js
 */
import { defineStore } from 'pinia';
export const useStore = defineStore('store', {
    state: () => ({
        tableId: '',
        isTried: false,
        promoterDrawer: false,
        flowPermission1: {},
        approverDrawer: false,
        approverConfig1: {},
        copyerDrawer: false,
        copyerConfig1: {},
        conditionDrawer: false,
        conditionsConfig1: {
            conditionNodes: [],
        },
        condtionData: [],
    }),
    actions: {
        setTableId(payload) {
            this.tableId = payload;
        },
        setIsTried(payload) {
            this.isTried = payload;
        },
        setPromoter(payload) {
            this.promoterDrawer = payload;
        },
        setFlowPermission(payload) {
            this.flowPermission1 = payload;
        },
        setApprover(payload) {
            this.approverDrawer = payload;
        },
        setApproverConfig(payload) {
            this.approverConfig1 = payload;
        },
        setCopyer(payload) {
            this.copyerDrawer = payload;
        },
        setCopyerConfig(payload) {
            this.copyerConfig1 = payload;
        },
        setCondition(payload) {
            this.conditionDrawer = payload;
        },
        setConditionsConfig(payload) {
            this.conditionsConfig1 = payload;
        },
        setcondtionData(payload) {
            this.condtionData = payload;
        },
    },
});
