/*
 * @Description:
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2023-09-02 17:25:42
 * @LastEditors: Seven
 * @LastEditTime: 2024-03-05 14:31:14
 */
import { createRouter, createWebHashHistory, createWebHistory, } from 'vue-router';
import { setupPermissions } from './permissions';
import { authentication, isHashRouterMode, publicPath } from '@/config';
import { constantRoutes, asyncRoutes } from './routes';
const router = createRouter({
    history: isHashRouterMode
        ? createWebHashHistory(publicPath)
        : createWebHistory(publicPath),
    routes: constantRoutes,
});
function fatteningRoutes(routes) {
    return routes.flatMap((route) => {
        return route.children ? fatteningRoutes(route.children) : route;
    });
}
export function addRouter(routes) {
    routes.forEach((route) => {
        if (!router.hasRoute(route.name))
            router.addRoute(route);
        if (route.children)
            addRouter(route.children);
    });
}
export function resetRouter(routes = constantRoutes) {
    routes.map((route) => {
        if (route.children)
            route.children = fatteningRoutes(route.children);
    });
    router.getRoutes().forEach(({ name }) => {
        router.hasRoute(name) &&
            router.removeRoute(name);
    });
    addRouter(routes);
}
export function setupRouter(app) {
    if (authentication === 'intelligence')
        addRouter(asyncRoutes);
    setupPermissions(router);
    app.use(router);
    return router;
}
export default router;
